import React, { useState } from 'react';
import { Grid, Box, useMediaQuery, useTheme, Button, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AllLandPackagesShow from './AllLandpackagesShow';
import AllUmrahPackagesShow from './AllUmrahPackagesShow';
import FilterComponent from './FilterComponent';
import AllHalalTravelPackages from './AllHalalTravelPackages';

const PackagesPage = () => {
  const location = useLocation();
  const theme = useTheme();

  // Check if the screen is large or mobile
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const allUmrahPackages = location.state?.allUmrahPackages || [];
  const allLandPackages = location.state?.allLandPackages || [];
  const allHalalPackages = location.state?.allHalalPackages || [];

  // Combine all packages for filtering options
  const allPackages = [...allUmrahPackages, ...allLandPackages,...allHalalPackages];
  console.log("allPackages",allPackages)
  // Extract unique cities and room types
  const uniqueCities = [
    ...new Set(
      allPackages.flatMap((pkg) => pkg?.flightDetails.map((flight) => flight.city))
    ),
  ];
  const uniquePartners = [
    ...new Set(
      allPackages.flatMap((pkg)=>pkg?.partnerDetails?.name)
    )
  ]
  const uniqueRoomTypes = [
    ...new Set(
      allPackages.flatMap((pkg) =>
        pkg.hotelTypes?.flatMap((hotel) =>
          hotel.hotelRoomPrice?.map((room) => room.RoomTypes)
        )
      )
    ),
  ];

  const uniqueHotelTypes = [
    ...new Set(
      allPackages.flatMap((pkg) =>
        pkg.hotelTypes?.map((hotel) => hotel.HotelStars)
      )
    ),
  ];

  // State for filtered packages
  const [filteredUmrahPackages, setFilteredUmrahPackages] = useState(allUmrahPackages);
  const [filteredLandPackages, setFilteredLandPackages] = useState(allLandPackages);
  const [isFilterOpen, setIsFilterOpen] = useState(false); // State for mobile filter visibility
  const [filteredHalalPackages, setFilteredHalalPackages] = useState(allHalalPackages);
  // Handle Filter Change
  const handleFilterChange = (filters) => {
    const selectedCities = filters.selectedCities || []; // Array of selected cities
    const selectedRoomTypes = filters.selectedRoomTypes || []; // Array of selected room types
    const selectedPartners = filters.selectedPartners || []; // Array of selected partners
    const startDate = filters.startDate ? new Date(filters.startDate) : null; // Start date
    const endDate = filters.endDate ? new Date(filters.endDate) : null; // End date
  
  
    // Filter Umrah Packages
    const filteredUmrah = allUmrahPackages.filter((pkg) => {
      console.log('selectedCity',pkg?.flightDetails?.some((flight) => selectedCities.includes(flight?.city))    )
      const matchesCity =
        selectedCities.length === 0 ||
        pkg?.flightDetails?.some((flight) => selectedCities.includes(flight?.city));
  
      const matchesRoomType =
        selectedRoomTypes.length === 0 ||
        pkg.hotelTypes.some((hotel) =>
          hotel.hotelRoomPrice.some((room) =>
            selectedRoomTypes.includes(room.RoomTypes)
          )
        );
  
      const matchesPartners =
        selectedPartners.length === 0 ||
        selectedPartners.includes(pkg?.partnerDetails?.name);
  
      const matchesDate =
        (!startDate && !endDate) ||
        pkg?.packageDateRange?.some((dateRange) => {
          const packageStartDate = new Date(dateRange.dateFrom);
          const packageEndDate = new Date(dateRange.dateTo);
          return (
            (!startDate || packageStartDate >= startDate) &&
            (!endDate || packageEndDate <= endDate)
          );
        });
  
        const matchesInsurance =
        filters.needInsurance === false || pkg.NeedInsurance === filters.needInsurance;
  
      const matchesActivity =
        filters.needActivity === false || pkg.NeedActivity === filters.needActivity;
  
      const matchesTransfer =
        filters.needTransfer === false || pkg.NeedTransfer === filters.needTransfer;
        const matchesFlights =
  filters.needFlights === false || (filters.needFlights === true && pkg.flightDetails.length > 0);

      const matchSearch =
        !filters.searchQuery ||
        pkg?.title?.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
        pkg?.description?.toLowerCase().includes(filters.searchQuery.toLowerCase());
  
      setIsFilterOpen(false);
  
      return (
        matchesCity &&
        matchesRoomType &&
        matchesPartners &&
        matchesDate &&
        matchesInsurance &&
        matchSearch &&
        matchesActivity &&
        matchesTransfer &&
        matchesFlights
      );
    });
  
    setFilteredUmrahPackages(filteredUmrah);
    
    //Filter Halal packages
    const filteredHalal = allHalalPackages.filter((pkg) => {
      const matchesCity =
        selectedCities.length === 0 ||
        pkg?.flightDetails?.some((flight) => selectedCities.includes(flight?.city));
  
      const matchesRoomType =
        selectedRoomTypes.length === 0 ||
        pkg.hotelTypes.some((hotel) =>
          hotel.hotelRoomPrice.some((room) =>
            selectedRoomTypes.includes(room.RoomTypes)
          )
        );
  
      const matchesPartners =
        selectedPartners.length === 0 ||
        selectedPartners.includes(pkg?.partnerDetails?.name);
  
      const matchesDate =
        (!startDate && !endDate) ||
        pkg?.packageDateRange?.some((dateRange) => {
          const packageStartDate = new Date(dateRange.dateFrom);
          const packageEndDate = new Date(dateRange.dateTo);
          return (
            (!startDate || packageStartDate >= startDate) &&
            (!endDate || packageEndDate <= endDate)
          );
        });
  
        const matchesInsurance =
        filters.needInsurance === false || pkg.NeedInsurance === filters.needInsurance;
  
      const matchesActivity =
        filters.needActivity === false || pkg.NeedActivity === filters.needActivity;
  
      const matchesTransfer =
        filters.needTransfer === false || pkg.NeedTransfer === filters.needTransfer;
        const matchesFlights =
  filters.needFlights === false || (filters.needFlights === true && pkg.flightDetails.length > 0);

      const matchSearch =
        !filters.searchQuery ||
        pkg?.title?.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
        pkg?.description?.toLowerCase().includes(filters.searchQuery.toLowerCase());
  
      setIsFilterOpen(false);
  
      return (
        matchesCity &&
        matchesRoomType &&
        matchesPartners &&
        matchesDate &&
        matchesInsurance &&
        matchSearch &&
        matchesActivity &&
        matchesTransfer &&
        matchesFlights
      );
    });

    setFilteredHalalPackages(filteredHalal);
    // Filter Land Packages
    const filteredLand = allLandPackages.filter((pkg) => {
      const matchesCity =
        selectedCities.length === 0 ||
        pkg?.flightDetails?.some((flight) => selectedCities.includes(flight?.city));
  
      const matchesRoomType =
        selectedRoomTypes.length === 0 ||
        pkg.hotelTypes.some((hotel) =>
          hotel.hotelRoomPrice.some((room) =>
            selectedRoomTypes.includes(room.RoomTypes)
          )
        );
  
      const matchesPartners =
        selectedPartners.length === 0 ||
        selectedPartners.includes(pkg?.partnerDetails?.name);
  
      const matchesDate =
        (!startDate && !endDate) ||
        pkg?.date_from_to?.some((dateRange) => {
          const packageStartDate = new Date(dateRange.dateFrom);
          const packageEndDate = new Date(dateRange.dateTo);
          return (
            (!startDate || packageStartDate >= startDate) &&
            (!endDate || packageEndDate <= endDate)
          );
        });
  
      const matchesInsurance =
        filters.needInsurance === false || pkg.NeedInsurance === filters.needInsurance;
        const matchesActivity =
        filters.needActivity === false || pkg.NeedActivity === filters.needActivity;
  
      const matchesTransfer =
        filters.needTransfer === false || pkg.NeedTransfer === filters.needTransfer;
        const matchesFlights =
        filters.needFlights === false || pkg.NeedFlights === filters.needFlights;
  
      const matchSearch =
        !filters.searchQuery ||
        pkg?.name?.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
        pkg.description?.toLowerCase().includes(filters.searchQuery.toLowerCase());
  
      return (
        matchesCity &&
        matchesRoomType &&
        matchesPartners &&
        matchesDate &&
        matchesInsurance &&
        matchSearch &&
        matchesActivity &&
        matchesTransfer &&
        matchesFlights
      );
    });
  
    setFilteredLandPackages(filteredLand);
  };
  
  

  return (
    <Box
      sx={{
        padding: isLargeScreen ? 10 : 0,
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={4}>
        {/* Filters Section */}
        {isMobileScreen || isMediumScreen ? (
          <>
            <Button
              variant="contained"
              onClick={() => setIsFilterOpen(true)}
              sx={{
                margin: 5,
                backgroundColor: '#004e8c',
                color: '#fff',
              }}
            >
              Show Filters
            </Button>
            <Drawer
              anchor="left"
              open={isFilterOpen}
              onClose={() => setIsFilterOpen(false)}
              sx={{ width: '75%' }}
            >
              <Box
                sx={{
                  width: isMobileScreen?340:400,
                  padding: 2,
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: 3,
                }}
              >
                <FilterComponent
                  onFilterChange={handleFilterChange}
                  filterOptions={{
                    cities: uniqueCities,
                    roomTypes: uniqueRoomTypes,
                    hotelTypes: uniqueHotelTypes,
                    partners:uniquePartners,

                  }}
                />
              </Box>
            </Drawer>
          </>
        ) : (
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              position: 'sticky',
              top: '10px',
              alignSelf: 'flex-start',
              height: 'auto',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#ffffff',
              }}
            >
              <FilterComponent
                onFilterChange={handleFilterChange}
                filterOptions={{
                  cities: uniqueCities,
                  roomTypes: uniqueRoomTypes,
                  hotelTypes: uniqueHotelTypes,
                  partners:uniquePartners
                }}
              />
            </Box>
          </Grid>
        )}

        {/* All Packages Section */}
        <Grid
          item
          xs={12}
          md={9}
          lg={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AllLandPackagesShow allLandPackages={filteredLandPackages} />
          <AllUmrahPackagesShow allUmrahPackages={filteredUmrahPackages} />
          <AllHalalTravelPackages allHalalPackages = {filteredHalalPackages}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackagesPage;
