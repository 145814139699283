import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  IconButton,
  useMediaQuery,
  useTheme,
  Paper,
  Tooltip
} from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import HotelIcon from '@mui/icons-material/Hotel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { FaHotel,FaCalendar } from 'react-icons/fa'; // Import hotel icon from Font Awesome

const AllHalalTravelPackages = ({ allHalalPackages }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check for mobile screens

  // State to control the number of visible packages
  const [visiblePackages, setVisiblePackages] = useState(isMobile ? 3 : 6);

  const defaultVisible = isMobile ? 3 : 6;

  const handleShowMore = () => setVisiblePackages(allHalalPackages.length);
  const handleShowLess = () => setVisiblePackages(3);

  const handleViewDetails = (packageData) => {
    navigate(`/package/${packageData?.id}/details`, {
      state: { packageData },
    });
  };

  return (
    <Box
      sx={{
        padding: isMobile ? 2 : 4,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Header Section */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: '#FF8C42',
          marginBottom: 2,
          textAlign: 'center',
        }}
      >
        Explore Halal Travel Packages
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'black',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 4,
          maxWidth: '600px',
        }}
      >
        {allHalalPackages.length > 0
          ? 'Discover our tailored Halal packages designed to make your journey unforgettable!'
          : 'Sorry, no Halal Packages are currently available. Please check back later!'}
      </Typography>

      {/* Packages Grid */}
      <Grid container spacing={5} sx={{ justifyContent: 'center' }}>
        {allHalalPackages.slice(0, visiblePackages).map((pkg, index) => {
          const mainImage =
            pkg.image[0]?.formats?.medium?.url || pkg.image[0]?.url || '/default.jpg';
          const label = pkg.title || 'Untitled Package';
          const city = pkg.cities?.[0]?.CityTypes || 'Unknown City';
          const depositPrice = pkg.DepositPrice || 'N/A';
          const flightIncluded = pkg.flightDetails?.[0]?.isFlightIncluded || false;
          const transferIncluded = pkg.NeedTransfer || false;
          const activityIncluded = pkg.NeedActivity || false;
          const distanceKaaba = pkg.distance_from_kaaba ;
          const distanceMadina = pkg.DistanceFromMadinah;
          const travelDates = pkg.packageDateRange || [];
          const hotelTypes = pkg.hotelTypes || [];
          const totalDays = pkg.hotelTypes.map((hotel)=>hotel.totalDays)
          const partnerDetails =  pkg?.partnerDetails || [];
          const hoteDestination = pkg.hotelTypes.map((hotel)=>hotel.hotelDestination||"NA")
          const hotels = pkg.hotelTypes
          ? pkg.hotelTypes.map((hotel, index) => ({
              index,
              name: hotel.HotelNames || 'Unknown Hotel',
              stars: hotel.HotelStars || 0,
            }))
          : [];
          const roomTypeTotals = hotelTypes.reduce((acc, hotel) => {
            hotel.hotelRoomPrice?.forEach((room) => {
              const roomKey = room.RoomTypes;
              const roomPrice = Number(room.RoomPrice) * hotel.totalDays;
        
              if (acc[roomKey]) {
                acc[roomKey] += roomPrice;
              } else {
                acc[roomKey] = roomPrice;
              }
            });
            return acc;
          }, {});
          return (
            <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
              <Card
                sx={{
                  borderRadius: 3,
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
                  position: 'relative',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
            <Chip
              avatar={
                <Box
                  component="img"
                  src={partnerDetails?.logo?.url}
                  alt="Partner Logo"
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%', // Optional: Make it circular
                  }}
                />
              }
              label={partnerDetails?.name} // If you want to display the name along with the logo
              sx={{
                position: 'absolute',
                top: 10, // Distance from the top
                left: 10, // Distance from the right
                backgroundColor: '#FF8C42',
                color: '#fff',
                fontWeight: 'bold',
                '& .MuiChip-label': {
                  paddingLeft: 2, // Adjust padding if there's an avatar
                },
              }}
            />




                <Chip
                    label={pkg?.label}
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      backgroundColor: '#FF8C42',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  />
                {/* Package Image */}
                <img
                  src={mainImage}
                  alt={label}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                  }}
                />

                <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                    backgroundColor: '#F0F8FF', // Subtle blue background
                    padding: '12px 16px',
                    borderRadius: '8px',
                    border: '1px solid #004e8c', // Accent border for emphasis
                    maxWidth: '100%', // Responsive width
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                      color: '#004e8c',
                      fontWeight: 'bold',
                      fontSize: '1.25rem', // Slightly larger font for a polished look
                    }}
                  >
                    {label}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{
                      color: '#FF8C42',
                      fontWeight: 'bold',
                      fontSize: '1rem', // Subtle differentiation between title and city
                    }}
                  >
                    {city}
                  </Typography>
                </Box>

                  {/* Deposit Price */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#FFF4E6',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      gap: '8px',
                      maxWidth: '400px', // Optional: constrain the width
                      marginBottom: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#FF8C42',
                        fontSize: !isMobile&&'1.2rem',
                      }}
                    >
                      💰
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#004e8c',
                        fontSize: '1rem',
                      }}
                    >
                      Deposit Price: 
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#FF8C42',
                        fontSize: '1rem',
                      }}
                    >
                      ${depositPrice} Per Person
                    </Typography>
                  </Box>


                  {/* Hotel Details */}
                  {hotels.length > 0 ? (
  hotels.map(({ name, stars }, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 2,
        gap: 1.5, // Space between icon and text
        padding: '8px 12px',
        backgroundColor: '#F9F9F9',
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Hotel Icon */}
      <FaHotel style={{ fontSize: '24px', color: '#004e8c' }} />

      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            color: '#004e8c',
          }}
        >
          {name}
        </Typography>

        {stars > 0 && (
          <Typography
            variant="body2"
            sx={{
              color: '#FFD700',
              fontSize: '0.9rem',
              marginTop: '2px',
              lineHeight: 1.5,
            }}
          >
            {Array(stars)
              .fill('⭐')
              .join(' ')}
          </Typography>
        )}
      </Box>
    </Box>
  ))
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                          padding: '8px 12px',
                          backgroundColor: '#F9F9F9',
                          borderRadius: '8px',
                          border: '1px solid #E0E0E0',
                          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <FaHotel style={{ fontSize: '24px', color: '#004e8c' }} />
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            color: '#004e8c',
                          }}
                        >
                          Unknown Hotel
                        </Typography>
                      </Box>
                    )}         
                    {distanceKaaba && distanceMadina && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          backgroundColor: '#F9F9F9',
                          padding: '12px 16px',
                          borderRadius: '8px',
                          border: '1px solid #E0E0E0',
                          marginBottom: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 'bold',
                            color: '#004e8c',
                            fontSize: '1rem',
                          }}
                        >
                          Distance From Room:
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              color: '#004e8c',
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                              }}
                            >
                              {distanceKaaba}m
                            </Typography>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '0.9rem',
                                color: '#6B6B6B',
                              }}
                            >
                              from Kaaba
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              color: '#FF8C42',
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                              }}
                            >
                              {distanceMadina}m
                            </Typography>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '0.9rem',
                                color: '#6B6B6B',
                              }}
                            >
                              from Madina
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}


                  <Typography
                   variant="body2"
                    sx={{
                      marginBottom: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2, // Adds space between icon and text
                      flexWrap: 'wrap', // Ensures text wraps neatly on smaller screens
                      lineHeight: 1.6,
                    }}
                  >
            <Box component="span" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>

                    {/* Calendar Icon */}
                    <FaCalendar style={{ fontSize: '22px', color: '#004e8c' }} />

                    {/* Travel Dates */}
                    <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            color: '#004e8c',
                          }}
                        >Travel Dates:</Typography>
                      {travelDates.length > 0 ? (
                        travelDates.map((d, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{
                              color: '#004e8c',
                              fontWeight: 'bold',
                              backgroundColor: '#F9F9F9',
                              padding: '4px 8px',
                              borderRadius: '8px',
                              border: '1px solid #004e8c',
                            }}
                          >
                            {new Date(d.dateFrom).toLocaleDateString()} -{' '}
                            {new Date(d.dateTo).toLocaleDateString()}
                          </Box>
                        ))
                      ) : (
                        <Box component="span" sx={{ color: '#777' }}>
                          N/A
                        </Box>
                      )}
                    </Box>
                  </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                        {totalDays.length > 0 &&
                          totalDays.map((day, index) => (
                            <Box
                              key={index}
                              sx={{
                                backgroundColor: index === 0 ? '#f0f8ff' : index === 1 ? '#ffefdb' : '#f9f9f9',
                                padding: '8px',
                                borderRadius: '8px',
                                marginBottom: '8px',
                                textAlign: 'center',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                              }}
                            >
                          {index === 0 ? (
                            <Typography
                              sx={{ color: '#004e8c', fontWeight: 'bold' }}
                            >{`Days in ${hoteDestination[0]} ${day}`}</Typography>
                          ) : index === 1 ? (
                            <Typography
                              sx={{ color: '#FF8C42', fontWeight: 'bold' }}
                            >{`Days in ${hoteDestination[1]}: ${day}`}</Typography>
                          ) : (
                            <Typography
                              sx={{ color: '#666666', fontWeight: 'medium' }}
                            >{`Day ${index + 1}: ${day}`}</Typography>
                          )}
                        </Box>
                      ))}
                  </Typography>

                        {/* Accommodation */}
                        {hotelTypes?.length > 0 && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Centers content horizontally
                            justifyContent: 'center', // Centers content vertically
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              color: '#004e8c',
                              display: 'flex',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <HotelIcon sx={{ color: '#FF8C42', mr: 1 }} />
                            Accommodation Options:
                          </Typography>
                          <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        justifyContent: 'center', // Center align the cards
                        marginBottom: 4,
                      }}
                    >
                      {Object.entries(roomTypeTotals).map(([roomType, totalPrice], index) => (
                        <Paper
                          key={index}
                          elevation={3}
                          sx={{
                            padding: '12px 16px',
                            backgroundColor: '#F9F9F9',
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1,
                            minWidth: '150px', // Ensure consistent size for the room cards
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#333333',
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                            }}
                          >
                            {roomType}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: '#FF8C42',
                              fontWeight: 'bold',
                            }}
                          >
                            €{Math.round(totalPrice)} / Per Person
                          </Typography>
                        </Paper>
                      ))}
                    </Box>
                        </Box>
                      )}
                  {/* Included Services */}
                  <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={0} md={1}></Grid>
                    {flightIncluded && (
                      <Grid item xs={3.5} sx={{ display: 'flex', alignItems: 'center' }} >
                      <Tooltip title="Flights included">
                        <FlightIcon sx={{ color: '#FF8C42' }} />
                      </Tooltip>
                      <Typography color="#666666" variant="caption">
                        Flights
                      </Typography>
                    </Grid>
                    )}
                    {transferIncluded && (
                      <Grid item xs={3.5} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Transfers included">
                        <DirectionsCarFilledIcon sx={{ color: '#FF8C42' }} />
                      </Tooltip>
                      <Typography color="#666666" variant="caption">
                      Transfers 
                      </Typography>
                    </Grid>
                    )}
                    {activityIncluded && (
                      <Grid item xs={3.5} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Activities included">
                        <LocalActivityIcon sx={{ color: '#FF8C42' }} />                         
                      </Tooltip>
                      <Typography color="#666666" variant="caption">
                          Activities
                      </Typography>
                    </Grid>
                    )}
                  </Grid>

                 
                </CardContent>
                 {/* Forward Icon Button */}
                 <IconButton
                    sx={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '0px',
                      backgroundColor: '#FF8C42',
                      color: '#FFFFFF',
                      '&:hover': {
                        backgroundColor: '#E07032',
                      },
                    }}
                    onClick={() => handleViewDetails(pkg)}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
              </Card>
              
            </Grid>
          );
        })}
      </Grid>

      {/* Show More / Show Less Buttons */}
      {allHalalPackages.length > 3 && (
        <Box sx={{ marginTop: 4 }}>
          {visiblePackages < allHalalPackages.length ? (
            <Button
              variant="outlined"
              onClick={handleShowMore}
              sx={{
                marginRight: 2,
                color: '#004e8c',
                borderColor: '#004e8c',
                '&:hover': {
                  backgroundColor: '#004e8c',
                  color: '#fff',
                },
              }}
            >
              Show More
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={handleShowLess}
              sx={{
                marginRight: 2,
                color: '#FF8C42',
                borderColor: '#FF8C42',
                '&:hover': {
                  backgroundColor: '#FF8C42',
                  color: '#fff',
                },
              }}
            >
              Show Less
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AllHalalTravelPackages;
