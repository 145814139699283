import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const PackageDetailsCard = ({ description,hotelTypes, title, totalPrice }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '20px',
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent={isLargeScreen ? 'space-between' : 'center'}
      >
        {/* Title and Description */}
        <Grid item xs={12} lg={6}>
          <Typography
            variant="h5"
            sx={{
              color: '#004e8c',
              fontWeight: 'bold',
              marginBottom: '16px',
              textAlign: isLargeScreen ? 'left' : 'center',
              fontSize: isLargeScreen ? '24px' : '20px',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#FF8C42',
              lineHeight: '1.6',
              textAlign: isLargeScreen ? 'left' : 'center',
              fontSize: isLargeScreen ? '16px' : '14px',
            }}
          >
            {description}
          </Typography>
        </Grid>

        {/* Details Section */}
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} justifyContent="center">
            {/* Makkah */}
            <Grid item xs={4}>
              <Box
                sx={{
                  backgroundColor: '#FF8C42',
                  color: '#FFFFFF',
                  padding: '10px 15px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                {hotelTypes[0]?.hotelDestination} {hotelTypes[0]?.totalDays}N
              </Box>
            </Grid>
            {/* Madinah */}
            <Grid item xs={4}>
            <Box
                sx={{
                  backgroundColor: '#FF8C42',
                  color: '#FFFFFF',
                  padding: '10px 15px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                {hotelTypes[1]?.hotelDestination} {hotelTypes[1]?.totalDays} N
              </Box>
            </Grid>
            {/* Total Nights */}
            <Grid item xs={4}>
              <Box
                sx={{
                  backgroundColor: '#FF8C42',
                  color: '#FFFFFF',
                  padding: '10px 15px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                {hotelTypes[0]?.totalDays + hotelTypes[1]?.totalDays} Nights
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Price Section */}
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            textAlign: isLargeScreen ? 'right' : 'center',
            display: isLargeScreen ? 'flex' : 'block',
            justifyContent:'right',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#004e8c',
              color: '#FFFFFF',
              padding: '15px 20px',
              borderRadius: '10px',
              textAlign: isLargeScreen?'right':'center',
              marginTop: '20px',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                fontSize: isLargeScreen ? '28px' : '24px',
                marginBottom: '8px',
              }}
            >
              € {totalPrice.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageDetailsCard;
