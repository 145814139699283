import React, { useState } from 'react';
import { Box, Typography, Button, Modal,useMediaQuery, useTheme } from '@mui/material';
import BabyIcon from '@mui/icons-material/BabyChangingStation';
import GroupIcon from '@mui/icons-material/Group';
import HotelIcon from '@mui/icons-material/Hotel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import FlightIcon from '@mui/icons-material/Flight';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarIcon from '@mui/icons-material/Star';
import HotelImageModal from './HotelImageModal';

const InfantsDetails = ({
  selectedActivityId,
  selectedInsuranceIds,
  selectedTransferId,
  selectedFlightsId,
  infants,
  adults,
  selectedRoom,
  selectedMealPlanId,
  packageData,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showTravelDates, setShowTravelDates] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const toggleDetails = () => setShowDetails(!showDetails);
  const toggleTravelDates = () => setShowTravelDates(!showTravelDates);

  const travelDates = packageData.packageDateRange || [];
  const handleOpenModal = (hotel) => {
    setSelectedHotel(hotel);
    setOpenModal(true);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedHotel(null);
  };
  // Extract package details
  const hotelSummary = packageData.hotelTypes.map((hotel) => ({
    name: hotel.HotelNames,
    stars: hotel.HotelStars,
    city: hotel.hotelDestination,
    images: hotel.image.map((img) => img.url),
  }));

  const insuranceSummary = packageData.insurance.map(
    (insurance) => `${insurance.InsuranceType} (€${insurance.InsurancePrice})`
  );

  const flightSummary = packageData.flightDetails
    ? `Flights Included: ${packageData.flightDetails.map((flight) => flight.city).join(', ')}`
    : 'No Flights Included';

  const transferIncluded = !!selectedTransferId;
  const mealPlanIncluded = !!selectedMealPlanId;

  return (
    <Box
      sx={{
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        marginBottom: '24px',
        textAlign: 'center',
      }}
    >
      {/* Booking Summary Heading */}
      <Typography
        variant="h4"
        sx={{
          color: '#004e8c',
          fontWeight: 'bold',
          marginBottom: '16px',
          textAlign: 'center',
        }}
      >
        Buchungsübersicht
      </Typography>

      {/* Short Summary */}
      <Typography variant="body1" sx={{ marginBottom: '16px', color: '#555' }}>
        {packageData.description}
      </Typography>
 

      {/* Additional Details Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#004e8c',
          color: '#fff',
          fontWeight: 'bold',
          marginTop: '16px',
          transition: 'all 0.3s',
          '&:hover': {
            backgroundColor: '#003b6e',
            transform: 'scale(1.05)',
          },
        }}
        onClick={toggleDetails}
      >
        {showDetails ? 'Details verbergen' : 'Details anzeigen'}
      </Button>

      {showDetails && (
        <Box
          sx={{
            marginTop: '20px',
            padding: '20px',
            borderRadius: '12px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
           {/* Hotels Summary */}
      <Box sx={{ marginBottom: '16px', backgroundColor: '#f9f9f9', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}>
      {/* Hotels Section */}
    

      {hotelSummary.map((hotel, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'flex-start' : 'space-between',
            alignItems: isMobile ? 'flex-start' : 'center',
            marginBottom: '16px',
            padding: '8px 12px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
            gap: isMobile ? '12px' : 0,
          }}
        >
          {/* Hotel Details */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              gap: isMobile ? '4px' : '8px',
            }}
          >
            <Typography
              variant={isMobile ? 'body2' : 'body1'}
              sx={{
                color: '#004e8c',
                fontWeight: 'bold',
                marginRight: isMobile ? 0 : '8px',
              }}
            >
              Hotels:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: isMobile ? '4px' : '8px',
              }}
            >
              <HotelIcon sx={{ color: '#004e8c' }} />
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                sx={{ fontWeight: 'bold', marginRight: '8px' }}
              >
                {hotel.name}
              </Typography>
              <Typography
                variant={isMobile ? 'caption' : 'body2'}
                sx={{ color: '#555', display: 'flex', alignItems: 'center' }}
              >
                <LocationOnIcon sx={{ marginRight: '4px', fontSize: '1rem', color: '#FF8C42' }} />
                {hotel.city}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', color: '#FFD700' }}>
                {Array(hotel.stars)
                  .fill(null)
                  .map((_, starIndex) => (
                    <StarIcon key={starIndex} fontSize="small" />
                  ))}
              </Box>
            </Box>
          </Box>

          {/* Show Images Button */}
          <Button
            variant="outlined"
            sx={{
              fontSize: '0.85rem',
              color: '#004e8c',
              borderColor: '#004e8c',
              alignSelf: isMobile ? 'flex-start' : 'center',
              '&:hover': {
                backgroundColor: '#004e8c',
                color: '#fff',
              },
            }}
            onClick={() => handleOpenModal(hotel)}
          >
            Show Images
          </Button>
        </Box>
      ))}
      {/* Travel Dates Section */}
      
      <Box
      sx={{
        padding: isMobile ? '8px' : '16px',
        backgroundColor: '#eaf4fd',
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
        textAlign: isMobile ? 'left' : 'center',
        marginTop: '16px',
      }}
    >
      <Typography
        variant={isMobile ? 'body1' : 'h6'}
        sx={{
          color: '#004e8c',
          fontWeight: 'bold',
          marginBottom: '12px',

          textAlign:  'center',
        }}
      >
        Travel Dates
      </Typography>
      {travelDates.map((date, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'flex-start' : 'center',
            alignItems: 'center',
            gap: isMobile ? 0 : 1,
            marginBottom: index === travelDates.length - 1 ? 0 : '12px',
          }}
        >
          <EventIcon sx={{ color: '#004e8c', marginBottom: isMobile ? '4px' : 0 }} />
          <Typography
            variant="body2"
            sx={{
              color: '#004e8c',
              fontWeight: 'bold',
              marginBottom: isMobile ? '4px' : 0,
            }}
          >
            {new Date(date.dateFrom).toLocaleDateString()} - {new Date(date.dateTo).toLocaleDateString()}
          </Typography>
        </Box>
      ))}
    </Box>

     
    </Box>
          {/* Insurance Summary */}
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: selectedInsuranceIds?.length > 0 ? '#2e7d32' : '#d32f2f',
              fontWeight: 'bold',
            }}
          >
            <CheckCircleIcon sx={{ marginRight: '8px', color: selectedInsuranceIds?.length > 0 ? '#2e7d32' : '#d32f2f' }} />
            Insurance {selectedInsuranceIds.length>0?"Included":"Not Included"}
          </Typography>

          {/* Flight Summary */}
          {packageData.packageType="umrah"?
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: flightSummary.includes('Included') ? '#2e7d32' : '#d32f2f',
              fontWeight: 'bold',
            }}
          >
            <FlightIcon sx={{ marginRight: '8px', color: flightSummary.includes('Included') ? '#2e7d32' : '#d32f2f' }} />
            {flightSummary}
          </Typography>
          :
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: selectedFlightsId? '#2e7d32' : '#d32f2f',
              fontWeight: 'bold',
            }}
          >
            <FlightIcon sx={{ marginRight: '8px', color: selectedFlightsId? '#2e7d32' : '#d32f2f' }} />
           Flight {selectedFlightsId?"Included":"NotIncluded"}
          </Typography>
}
          {/* Meal Plans */}
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: mealPlanIncluded ? '#2e7d32' : '#d32f2f',
              fontWeight: 'bold',
            }}
          >
            <LocalDiningIcon sx={{ marginRight: '8px', color: mealPlanIncluded ? '#2e7d32' : '#d32f2f' }} />
            Meal Plan: {mealPlanIncluded ? 'Included' : 'Not Included'}
          </Typography>

          {/* Transfer */}
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: transferIncluded ? '#2e7d32' : '#d32f2f',
              fontWeight: 'bold',
            }}
          >
            <TransferWithinAStationIcon
              sx={{ marginRight: '8px', color: transferIncluded ? '#2e7d32' : '#d32f2f' }}
            />
            Transfer: {transferIncluded ? 'Included' : 'Not Included'}
          </Typography>

          {/* Room Type */}
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: '#333',
              fontWeight: 'bold',
            }}
          >
            <HotelIcon sx={{ marginRight: '8px', color: '#004e8c' }} />
            Room Type: {selectedRoom?.RoomTypes || 'N/A'}
          </Typography>

          {/* Additional Selections */}
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: '#333',
              fontWeight: 'bold',
            }}
          >
            <BabyIcon sx={{ marginRight: '8px', color: '#004e8c' }} />
            Infants: {infants}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              color: '#333',
              fontWeight: 'bold',
            }}
          >
            <GroupIcon sx={{ marginRight: '8px', color: '#004e8c' }} />
            Adults: {adults}
          </Typography>
        </Box>
      )}
       {/* Modal for Hotel Images */}
       <HotelImageModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          selectedHotel={selectedHotel}
        />
    </Box>
  );
};

export default InfantsDetails;
