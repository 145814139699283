import React, { useState } from 'react';
import {useTheme,Chip,Tooltip,useMediaQuery, Card, CardContent, Typography, IconButton, Box, Grid, Paper, Select, MenuItem } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import FlagIcon from '@mui/icons-material/Flag';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FaHotel,FaCalendar } from 'react-icons/fa'; // Import hotel icon from Font Awesome

const UmrahPackageCard = ({ packageData }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    title,
    label,
    image = [],
    packageDateRange = [],
    hotelTypes = [],
    cities = [],
    name,
    date_from_to = [],
    flightDetails,
    NeedActivity,
    NeedTransfer,
    distance_from_kaaba,
    DistanceFromMadinah,
    DepositPrice,
    partnerDetails
  } = packageData || {};
  const hotels = packageData.hotelTypes
  ? packageData.hotelTypes.map((hotel, index) => ({
      index,
      name: hotel.HotelNames || 'Unknown Hotel',
      stars: hotel.HotelStars || 0,
    }))
  : [];
  const roomTypeTotals = hotelTypes.reduce((acc, hotel) => {
    hotel.hotelRoomPrice?.forEach((room) => {
      const roomKey = room.RoomTypes;
      const roomPrice = Number(room.RoomPrice) * hotel.totalDays;

      if (acc[roomKey]) {
        acc[roomKey] += roomPrice;
      } else {
        acc[roomKey] = roomPrice;
      }
    });
    return acc;
  }, {});


  const totalDays = packageData.hotelTypes.map((hotel)=>hotel.totalDays)
    console.log("packageData",packageData)
  const handleViewDetails = () => {
    navigate(`/package/${packageData?.id}/details`, {
      state: { packageData }
    });
  };

  return (
    <Card
      sx={{
        margin: { xs: '10px', sm: '20px' },
        width: '100%',
        backgroundColor: "#FFFFFF",  // White background for the card
        borderRadius: '12px',  // Rounded corners
        overflow: 'hidden',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
        position: 'relative',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.03)', // Slight scaling on hover for interactivity
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)', // Light shadow on hover
        }
      }}
    >
     <Chip
        avatar={
          <Box
            component="img"
            src={partnerDetails?.logo?.url}
            alt="Partner Logo"
            sx={{
              width: 24,
              height: 24,
              borderRadius: '50%', // Optional: Make it circular
            }}
          />
        }
        label={partnerDetails?.name} // If you want to display the name along with the logo
        sx={{
          position: 'absolute',
          top: 10, // Distance from the top
          left: 10, // Distance from the right
          backgroundColor: '#FF8C42',
          color: '#fff',
          fontWeight: 'bold',
          '& .MuiChip-label': {
            paddingLeft: 2, // Adjust padding if there's an avatar
          },
        }}
      />

        <Chip
                    label={label}
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      backgroundColor: '#FF8C42',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  />
      <img
        src={image.length > 0 ? image[0].url : '/default.jpg'}
        alt={image.length > 0 ? image[0].name : 'No image available'}
        style={{
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}
      />

      <CardContent sx={{ paddingBottom: '60px' }}>
        {/* Title and City Names */}
       
                  <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                    backgroundColor: '#F0F8FF', // Subtle blue background
                    padding: '12px 16px',
                    borderRadius: '8px',
                    border: '1px solid #004e8c', // Accent border for emphasis
                    maxWidth: '100%', // Responsive width
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                      color: '#004e8c',
                      fontWeight: 'bold',
                      fontSize: '1.25rem', // Slightly larger font for a polished look
                    }}
                  >
                    {title?title:name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{
                      color: '#FF8C42',
                      fontWeight: 'bold',
                      fontSize: '1rem', // Subtle differentiation between title and city
                    }}
                  >
                    {cities.map(city => city.CityTypes).join(', ')}
                  </Typography>
                </Box>
         {/* Deposit Price */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#FFF4E6',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      gap: isMobile?'2px':'8px',
                      maxWidth: '400px', // Optional: constrain the width
                      marginBottom: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#FF8C42',
                        fontSize: '1.2rem',
                      }}
                    >
                      💰
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#004e8c',
                        fontSize: '1rem',
                      }}
                    >
                      Deposit Price: 
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#FF8C42',
                        fontSize: '1rem',
                      }}
                    >
                      ${DepositPrice} Per Person
                    </Typography>
                  </Box>
                 {/* Hotel Details */}
                 {hotels.length > 0 ? (
                    hotels.map(({ name, stars }, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 2,
                          gap: 1.5, // Space between icon and text
                          padding: '8px 12px',
                          backgroundColor: '#F9F9F9',
                          borderRadius: '8px',
                          border: '1px solid #E0E0E0',
                          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {/* Hotel Icon */}
                        <FaHotel style={{ fontSize: '24px', color: '#004e8c' }} />

                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              color: '#004e8c',
                            }}
                          >
                            {name}
                          </Typography>

                          {stars > 0 && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#FFD700',
                                fontSize: '0.9rem',
                                marginTop: '2px',
                                lineHeight: 1.5,
                              }}
                            >
                              {Array(stars)
                                .fill('⭐')
                                .join(' ')}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ))
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                          padding: '8px 12px',
                          backgroundColor: '#F9F9F9',
                          borderRadius: '8px',
                          border: '1px solid #E0E0E0',
                          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <FaHotel style={{ fontSize: '24px', color: '#004e8c' }} />
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            color: '#004e8c',
                          }}
                        >
                          Unknown Hotel
                        </Typography>
                      </Box>
                    )}
                 

                  {distance_from_kaaba&&DistanceFromMadinah&&
                  <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          backgroundColor: '#F9F9F9',
                          padding: '12px 16px',
                          borderRadius: '8px',
                          border: '1px solid #E0E0E0',
                          marginBottom: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 'bold',
                            color: '#004e8c',
                            fontSize: '1rem',
                          }}
                        >
                          Distance From Room:
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              color: '#004e8c',
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                              }}
                            >
                              {distance_from_kaaba}m
                            </Typography>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '0.9rem',
                                color: '#6B6B6B',
                              }}
                            >
                              from Kaaba
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              color: '#FF8C42',
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                              }}
                            >
                              {DistanceFromMadinah}m
                            </Typography>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '0.9rem',
                                color: '#6B6B6B',
                              }}
                            >
                              from Madina
                            </Typography>
                          </Box>
                        </Box>
                      </Box>}
                      {/* Travel Dates */}
                   <Typography
                   variant="body2"
                    sx={{
                      marginBottom: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2, // Adds space between icon and text
                      flexWrap: 'wrap', // Ensures text wraps neatly on smaller screens
                      lineHeight: 1.6,
                    }}
                  >
                   <Box component="span" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>

                    {/* Calendar Icon */}
                    <CalendarMonthIcon style={{ fontSize: '22px', color: '#004e8c' }} />

                    <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            color: '#004e8c',
                          }}
                        >Travel Dates:</Typography>
                      {packageDateRange?.length > 0 ? (
                        packageDateRange.map((d, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{
                              color: '#004e8c',
                              fontWeight: 'bold',
                              backgroundColor: '#F9F9F9',
                              padding: '4px 8px',
                              borderRadius: '8px',
                              border: '1px solid #004e8c',
                            }}
                          >
                            {new Date(d.dateFrom).toLocaleDateString()} -{' '}
                            {new Date(d.dateTo).toLocaleDateString()}
                          </Box>
                        ))
                      ) : (
                        <Box component="span" sx={{ color: '#777' }}>
                          N/A
                        </Box>
                      )}
                    </Box>
                  </Typography>

                 

                          {/* Accommodation */}
                          {hotelTypes?.length > 0 && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Centers content horizontally
                            justifyContent: 'center', // Centers content vertically
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              color: '#004e8c',
                              display: 'flex',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <HotelIcon sx={{ color: '#FF8C42', mr: 1 }} />
                            Accommodation Options:
                          </Typography>
                          <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        justifyContent: 'center', // Center align the cards
                        marginBottom: 4,
                      }}
                    >
                      {Object.entries(roomTypeTotals).map(([roomType, totalPrice], index) => (
                        <Paper
                          key={index}
                          elevation={3}
                          sx={{
                            padding: '12px 16px',
                            backgroundColor: '#F9F9F9',
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1,
                            minWidth: '150px', // Ensure consistent size for the room cards
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#333333',
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                            }}
                          >
                            {roomType}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: '#FF8C42',
                              fontWeight: 'bold',
                            }}
                          >
                            €{Math.round(totalPrice)} / Per Person
                          </Typography>
                        </Paper>
                      ))}
                    </Box>
                        </Box>
                      )}


       {/* Included Services */}
       <Grid container spacing={0} sx={{ mt: 2 }}>
                    {flightDetails.length>0 && (
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <FlightIcon sx={{ color: '#FF8C42', mr: 0.5 }} />
                        <Typography color="#666666" variant="caption">
                          Flights
                        </Typography>
                      </Grid>
                    )}
                    {NeedTransfer && (
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <DirectionsCarFilledIcon sx={{ color: '#FF8C42', mr: 0.5 }} />
                        <Typography color="#666666" variant="caption">
                          Transfers
                        </Typography>
                      </Grid>
                    )}
                    {NeedActivity && (
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <HotelIcon sx={{ color: '#FF8C42', mr: 0.5 }} />
                        <Typography color="#666666" variant="caption">
                          Activities
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
 {/* Right Arrow Button */}
 <IconButton
        sx={{
          position: 'absolute',
          bottom: '0px',
          right: '0px',
          backgroundColor: '#FF8C42',
          color: '#FFFFFF',
          fontSize:isMobile?0.5:'',
          '&:hover': {
            backgroundColor: '#E07032',
          },
        }}
        onClick={handleViewDetails}
      >
        <ArrowForwardIcon />
      </IconButton>
      
      </CardContent>

     
    </Card>
  );
};

export default UmrahPackageCard;
