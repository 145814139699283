import React, { useState } from 'react';
import { useTheme,useMediaQuery,Box, Typography, Grid, Paper, Dialog, DialogContent, IconButton, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import Translation_german from 'src/components/Translation/translation_german';
const HotelDetailsSection = ({ hotelTypes }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentHotel, setCurrentHotel] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // Open the modal and set the current image
  const handleOpenDialog = (hotel, index) => {
    setCurrentImage(hotel.image[index].url); // Start with the clicked image
    setCurrentImageIndex(index); // Set image index to clicked image
    setCurrentHotel(hotel); // Set the selected hotel
    setOpenDialog(true);
  };

  // Close the modal
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Navigate to the previous image
  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImage(currentHotel.image[currentImageIndex - 1]?.url);
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  // Navigate to the next image
  const handleNextImage = () => {
    if (currentImageIndex < currentHotel.image.length - 1) {
      setCurrentImage(currentHotel.image[currentImageIndex + 1]?.url);
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  return (
<Box sx={{ mt: 6 }}>
  {hotelTypes.map((hotel) => (
    <Box
      key={hotel._id}
      sx={{
        mt: 6,
        padding: '20px',
        background: '#f9f9f9',
        borderRadius: '12px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Hotel Name */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          color: '#004e8c',
          mb: 2,
          textAlign: 'center',
          fontSize: '1.2rem',
        }}
      >
        {hotel.hotelDestination} Hotel: {hotel.HotelNames}
      </Typography>

      {/* Main Image and Thumbnails */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Main Image */}
        <Box
          sx={{
            width: '100%',
            maxWidth: '300px',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
            mb: 3,
            cursor: 'pointer',
            '&:hover': {
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
            },
          }}
          onClick={() => handleOpenDialog(hotel, 0)}
        >
          <Box
            component="img"
            src={hotel?.image[0]?.formats?.large?.url || hotel?.image[0]?.url}
            alt={hotel?.HotelNames}
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </Box>

        {/* Thumbnails */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          {hotel.image.slice(1, 5).map((img, imgIndex) => (
            <Box
              key={img._id}
              sx={{
                width: '120px',
                height: '80px',
                borderRadius: '8px',
                overflow: 'hidden',
                cursor: 'pointer',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
                },
              }}
              onClick={() => handleOpenDialog(hotel, imgIndex + 1)}
            >
              <Box
                component="img"
                src={img.formats?.thumbnail?.url || img.url}
                alt={img.name}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          ))}
        </Box>

        {/* View All Button */}
        {hotel.image.length > 5 && (
          <Button
            variant="contained"
            sx={{
              display: 'block',
              margin: '20px auto 0',
              color: '#fff',
              background: '#004e8c',
              borderRadius: '20px',
              fontWeight: 'bold',
              padding: '10px 20px',
              textTransform: 'none',
              '&:hover': {
                background: '#003366',
              },
            }}
            onClick={() => handleOpenDialog(hotel, 0)}
          >
            {Translation_german.VIEW_ALL_PHOTOS_BUTTON}
          </Button>
        )}
      </Box>
    </Box>
  ))}

  {/* Dialog */}
  <Dialog
    open={openDialog}
    onClose={handleCloseDialog}
    maxWidth="lg"
    fullWidth
    PaperProps={{
      sx: {
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        borderRadius: '12px',
      },
    }}
  >
    <DialogContent sx={{ position: 'relative', p: 0 }}>
      {/* Navigation Buttons */}
      <IconButton
        onClick={handlePrevImage}
        disabled={currentImageIndex === 0}
        sx={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <Box
        component="img"
        src={currentImage}
        alt="Hotel"
        sx={{
          width: '100%',
          maxHeight: '90vh',
          objectFit: 'contain',
        }}
      />

      <IconButton
        onClick={handleNextImage}
        disabled={currentImageIndex === currentHotel?.image.length - 1}
        sx={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>

      <IconButton
        onClick={handleCloseDialog}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogContent>
  </Dialog>
</Box>


  );
};

export default HotelDetailsSection;
