import React, { useState } from 'react';
import { Grid, Typography, Paper, Tab, Tabs, Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Translation_german from 'src/components/Translation/translation_german';

const PackageInclusionsExclusions = ({ packages }) => {
  const { Inclusions, Exclusions } = packages?.packages;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const initialVisibleItems = 5;

  const [showAllIncludes, setShowAllIncludes] = useState(false);
  const [showAllExclusions, setShowAllExclusions] = useState(false);

  const toggleShowAllIncludes = () => setShowAllIncludes(!showAllIncludes);
  const toggleShowAllExclusions = () => setShowAllExclusions(!showAllExclusions);

  const paperStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    textAlign: 'center',
    borderRadius: 10,
    width: '100%',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    },
  };

  const iconStyle = {
    width: isMobile ? 20 : 35,
    height: isMobile ? 20 : 35,
    marginRight: '12px',
    filter: 'grayscale(100%)',
    transition: 'filter 0.3s ease',
    '&:hover': {
      filter: 'none',
    },
  };

  const tabStyle = {
    fontSize: isMobile ? '0.75rem' : '1rem',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: isMobile ? '5px 10px' : '10px 20px',
    color: tabValue === 0 ? '#004e8c' : '#999999',
  };

  const renderItems = (items, showAll, toggleShow, label) => (
    <>
      {items?.length > 0 ? (
        
        <Grid container spacing={3}>
          {items.slice(0, showAll ? items.length : initialVisibleItems).map((item) => (
            <Grid item xs={12} sm={6} md={6} key={item._id} align="center">
              <Paper sx={paperStyle}>
                <img
                  src={item.IncludeIcons?.url || item.Icon?.url || ''}
                  alt={item.IncludeIcons?.alternativeText || item.Icon?.alternativeText || 'Icon'}
                  style={iconStyle}
                />
                <Typography
                  sx={{
                    color: label === 'Include' ? '#004e8c' : '#FF8C42',
                    fontWeight: 'bold',
                    fontSize: isMobile ? '0.75rem' : '1rem',
                  }}
                >
                  {item.Include || item.Exclusion}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px', // Add height for vertical alignment
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              color: '#999',
              fontSize: isMobile ? '0.9rem' : '1.2rem',
            }}
          >
            {`Keine Artikel ${label === 'Include' ? 'Include' : 'Exclude'}`}
          </Typography>
        </Box>
      )}
      {items?.length > initialVisibleItems && (
        <Box textAlign="center" mt={2}>
          <Button
            variant="text"
            color="primary"
            sx={{ fontSize: isMobile ? '0.75rem' : '1rem' }}
            onClick={toggleShow}
          >
            {showAll
              ? Translation_german.VIEW_LESS_BUTTON
              : Translation_german.VIEW_MORE_BUTTON}
          </Button>
        </Box>
      )}
    </>
  );

  return (
    <Box sx={{ padding: isMobile ? '1rem' : '2rem' }}>
      {isMobile ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              centered
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{
                style: { backgroundColor: '#FF8C42', height: '3px' },
              }}
            >
              <Tab label={Translation_german.INCLUDED_SERVICES} sx={tabStyle} />
              <Tab label={Translation_german.SERVICES_EXCLUDED} sx={tabStyle} />
            </Tabs>
          </Box>

          {tabValue === 0 && (
            <Box sx={{ mt: 2 }}>
              {renderItems(Inclusions, showAllIncludes, toggleShowAllIncludes, 'Include')}
            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ mt: 2 }}>
              {renderItems(Exclusions, showAllExclusions, toggleShowAllExclusions, 'Exclusion')}
            </Box>
          )}
        </>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: '#004e8c',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 2,
                fontSize: '1.5rem',
              }}
            >
              {Translation_german.INCLUDED_SERVICES}
            </Typography>
            {renderItems(Inclusions, showAllIncludes, toggleShowAllIncludes, 'Include')}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                color: '#FF8C42',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 2,
                fontSize: '1.5rem',
              }}
            >
              {Translation_german.SERVICES_EXCLUDED}
            </Typography>
            {renderItems(Exclusions, showAllExclusions, toggleShowAllExclusions, 'Exclusion')}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PackageInclusionsExclusions;
