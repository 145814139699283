import React, { useState } from 'react';
import { useTheme,Tooltip,Chip,useMediaQuery,Card, CardContent, Typography, IconButton, Box, Grid, Paper, Select, MenuItem } from '@mui/material';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import FlightIcon from '@mui/icons-material/Flight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import HotelIcon from '@mui/icons-material/Hotel';

const LandPackageCard = ({ packageData }) => {
  const theme= useTheme();
  const isMobile =  useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const {
    name,
    image = [],
    date_from_to = [],
    NeedTransfer,
    NeedActivity,
    NeedFlights,
    price = [],
    distance_from_kaaba='',
    distanceFromMadina = [],
    DepositPrice=[],
    label='',
    hotelTypes
  } = packageData || {};
  const hotel = packageData.hotelTypes?.[0]?.HotelNames || 'Hotel not specified';
  const hotelStars = packageData.hotelTypes?.[0]?.HotelStars || 0;
  const cities = packageData.cities?.[0]?.CityTypes || 'Unknown City';
  const totalDays = packageData.hotelTypes.map((hotel)=>hotel.totalDays)




  const handleViewDetails = () => {
    navigate(`/land-package/${packageData?.id}/details`, {
      state: { packageData },
    });
  };

  return (
    <Card
      sx={{
        margin: { xs: '10px', sm: '20px' },
        width: '100%',
        maxWidth: '500px',
        backgroundColor: "#FFFFFF",  // White background for the card
        borderRadius: '12px',  // Rounded corners
        overflow: 'hidden',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
        position: 'relative',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.03)', // Slight scaling on hover
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)', // Light shadow on hover
        }
      }}
    >
      <img
        src={image.length > 0 ? image[0].url : '/default.jpg'}
        alt={image.length > 0 ? image[0].name : 'No image available'}
        style={{
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}
      />
 <Chip
                    label={label}
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      backgroundColor: '#FF8C42',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  />
        <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ color: '#004e8c', fontWeight: 'bold', marginBottom: 1 }}
                  >
                    {name} - {cities}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: '#FF8C42',
                      fontWeight: 'bold',
                      marginBottom: 1,
                      fontSize: '1.1rem',
                    }}
                  >
                    💰 Deposit Price: ${DepositPrice} Per Person
                  </Typography>

                  <Typography variant="body2" sx={{ marginBottom: 1, lineHeight: 1.6 }}>
                    <b>Hotel:</b>{' '}
                    <Box component="span" sx={{ color: '#004e8c', fontWeight: 'bold' }}>
                      {hotel}
                    </Box>{' '}
                    {hotelStars > 0 && (
                      <Box component="span" sx={{ color: '#FFD700', fontSize: '1rem' }}>
                        {Array(hotelStars)
                          .fill('⭐')
                          .join(' ')}
                      </Box>
                    )}
                  </Typography>


                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <b>Distance From Room:</b>{' '}
                    <Box component="span" sx={{ color: '#004e8c', fontWeight: 'bold' }}>
                      {distance_from_kaaba}m
                    </Box>{' '}
                    from Kaaba,{' '}
                    <Box component="span" sx={{ color: '#FF8C42', fontWeight: 'bold' }}>
                      {distanceFromMadina}m
                    </Box>{' '}
                    from Madina
                  </Typography>


                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                      <b>Travel Dates:</b>{' '}
                      {date_from_to.length > 0 ? (
                        <Box component="ul" sx={{ margin: 0, paddingLeft: '20px', listStyle: 'disc' }}>
                          {date_from_to.map((d, index) => (
                            <Box component="li" key={index} sx={{ color: '#004e8c' }}>
                              {new Date(d.dateFrom).toLocaleDateString()} - {new Date(d.dateTo).toLocaleDateString()} ({d.price})
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                        {totalDays.length > 0 &&
                          totalDays.map((day, index) => (
                            <Box
                              key={index}
                              sx={{
                                backgroundColor: index === 0 ? '#f0f8ff' : index === 1 ? '#ffefdb' : '#f9f9f9',
                                padding: '8px',
                                borderRadius: '8px',
                                marginBottom: '8px',
                                textAlign: 'center',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                              }}
                            >
                          {index === 0 ? (
                            <Typography
                              sx={{ color: '#004e8c', fontWeight: 'bold' }}
                            >{`Days in Makkah: ${day}`}</Typography>
                          ) : index === 1 ? (
                            <Typography
                              sx={{ color: '#FF8C42', fontWeight: 'bold' }}
                            >{`Days in Madinah: ${day}`}</Typography>
                          ) : (
                            <Typography
                              sx={{ color: '#666666', fontWeight: 'medium' }}
                            >{`Day ${index + 1}: ${day}`}</Typography>
                          )}
                        </Box>
                      ))}
                  </Typography>
                    {hotelTypes?.length > 0 && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Centers content horizontally
                            justifyContent: 'center', // Centers content vertically
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              color: '#004e8c',
                              display: 'flex',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <HotelIcon sx={{ color: '#FF8C42', mr: 1 }} />
                            Accommodation Options:
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 2,
                              justifyContent: 'center', // Centers room cards horizontally
                            }}
                          >
                            {hotelTypes[0]?.hotelRoomPrice?.map((room, index) => (
                              <Paper
                                key={index}
                                elevation={3}
                                sx={{
                                  padding: '12px 16px',
                                  backgroundColor: '#F9F9F9',
                                  borderRadius: '12px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  gap: 1,
                                  minWidth: '150px', // Ensure consistent size for the room cards
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: '#333333',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {room.RoomTypes}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: '#FF8C42',
                                    fontWeight: 'medium',
                                  }}
                                >
                                  €{room.RoomPrice} / person
                                </Typography>
                              </Paper>
                            ))}
                          </Box>
                        </Box>
                      )}

                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    {NeedActivity && (
                      <Grid item xs={4}>
                        <Tooltip title="Activities included">
                          <LocalActivityIcon sx={{ color: '#004e8c' }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {NeedFlights && (
                      <Grid item xs={4}>
                        <Tooltip title="Flights included">
                          <FlightIcon sx={{ color: '#004e8c' }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {NeedTransfer && (
                      <Grid item xs={4}>
                        <Tooltip title="Transfers included">
                          <DirectionsCarFilledIcon sx={{ color: '#004e8c' }} />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>

                  
                </CardContent>

                <IconButton
                    sx={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '0px',
                      backgroundColor: '#004e8c',
                      color: '#FFFFFF',
                      '&:hover': {
                        backgroundColor: '#E07032',
                      },
                    }}
                    onClick={() => handleViewDetails}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
    </Card>
  );
};

export default LandPackageCard;
