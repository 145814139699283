import React, { useState } from 'react';
import { Box, Typography, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const HotelImageModal = ({ openModal, handleCloseModal, selectedHotel }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 1 < selectedHotel.images.length ? prevIndex + 1 : 0
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : selectedHotel.images.length - 1
    );
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: '600px',
          backgroundColor: 'white',
          padding: '16px',
          borderRadius: '12px',
          boxShadow: 24,
          textAlign: 'center',
        }}
      >
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: '#004e8c',
          }}
        >
          <CloseIcon />
        </IconButton>

        {selectedHotel && (
          <>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: '#004e8c',
                marginBottom: '16px',
              }}
            >
              {selectedHotel.name}
            </Typography>

            {selectedHotel.images.length > 0 ? (
              <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                {/* Previous Button */}
                <IconButton
                  onClick={handlePrevImage}
                  sx={{
                    position: 'absolute',
                    left: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    color: '#004e8c',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '50%',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>

                {/* Image */}
                <Box
                  component="img"
                  src={selectedHotel.images[currentImageIndex]}
                  alt={`Hotel Image ${currentImageIndex + 1}`}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                  }}
                />

                {/* Next Button */}
                <IconButton
                  onClick={handleNextImage}
                  sx={{
                    position: 'absolute',
                    right: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    color: '#004e8c',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '50%',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            ) : (
              <Typography variant="body2" sx={{ color: '#555' }}>
                No images available for this hotel.
              </Typography>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default HotelImageModal;
