
const Translation_german = {
    HOME: 'Startseite',
    UMRAH_PACKAGES: 'Umrah-Pakete',
    LAND_PACKAGES: 'Land-Pakete',
    GET_IN_TOUCH: 'Kontakt aufnehmen',
    B2B_COLLABORATION: 'B2B-Kooperation',
    
    // New translations for Index component
    UMRAH_PACKAGES_LABEL: 'Umrah-Pakete',
    LAND_PACKAGES_LABEL: 'Land-Pakete',

    // Translations for Filter component
    TRIP_TYPE_LABEL: 'Reisetyp',
    CITY_LABEL: 'Abflughafen',
    PACKAGE_LABEL: 'Paket',
    ROOM_TYPE_LABEL: 'Zimmertyp',
    DATE_LABEL: 'Datum',
    ADULTS_LABEL: 'Erwachsene',
    INFANTS_LABEL: 'Kleinkinder',
    MAX_ADULTS_ERROR: 'Maximal: ',
    MAX_INFANTS_ERROR: 'Maximal: ',
    ROOM_CAPACITY_ERROR: "Die ausgewählte Anzahl an Erwachsenen oder Kleinkindern überschreitet die Zimmerkapazität.",
    DATE_RANGE_ERROR: "Kein passender Zeitraum für das ausgewählte Datum gefunden.",
    PACKAGE_NOT_FOUND_ERROR: "Kein passendes Paket für die ausgewählten Filter gefunden.",
    TRAVEL_DATA_ERROR: "Reisedaten sind nicht korrekt strukturiert.",
    PRICE_NOT_AVAILABLE: "Preis nicht verfügbar",

    // Translations for LandPackageFilter component
    TRIP_TYPE_LABEL: 'Reisetyp',
    CITY_LABEL: 'Stadt',
    PACKAGE_LABEL: 'Paket',
    ROOM_TYPE_LABEL: 'Zimmertyp',
    DATE_LABEL: 'Datum',
    ADULTS_LABEL: 'Erwachsene',
    INFANTS_LABEL: 'Kleinkinder',
    MAX_ADULTS_ERROR: 'Maximal: ',
    MAX_INFANTS_ERROR: 'Maximal: ',
    ROOM_CAPACITY_ERROR: "Die ausgewählte Anzahl an Erwachsenen oder Kleinkindern überschreitet die Zimmerkapazität.",
    DATE_RANGE_ERROR: "Kein passender Zeitraum für das ausgewählte Datum gefunden.",
    PACKAGE_NOT_FOUND_ERROR: "Kein passendes Paket für die ausgewählten Filter gefunden.",
    TRAVEL_DATA_ERROR: "Reisedaten sind nicht korrekt strukturiert.",
    PRICE_NOT_AVAILABLE: "Preis nicht verfügbar",
    DEFAULT_ACTIVITY_PRICE: "Standard-Aktivitätspreis",
    DEFAULT_TRANSFER_PRICE: "Standard-Transferpreis",

    // Translations for Services component
    OUR_SERVICES_TITLE: 'Unsere Dienstleistungen',
    VIEW_MORE_BUTTON: 'Mehr anzeigen',
    VIEW_LESS_BUTTON: 'Weniger anzeigen',

    // Translations for UmrahPackages component
    POPULAR_UMRAH_PACKAGES_TITLE: 'Beliebte Umrah-Pakete',
    NO_UMRAH_PACKAGES_MESSAGE: 'Keine Umrah-Pakete verfügbar',

    // Translations for UmrahPackageCard component
    ACCOMMODATION_LABEL: 'Unterkunft',
    TRANSFERS_LABEL: 'Transfers',
    FLIGHTS_LABEL: 'Flüge',
    HOTELS_LABEL: 'Hotels',
    ACTIVITIES_LABEL: 'Aktivitäten',
    PRICE_LABEL: 'Preis',
    NO_IMAGE_AVAILABLE: 'Kein Bild verfügbar',

    // Translations for LandPackages component
    POPULAR_LAND_PACKAGES_TITLE: 'Beliebte Land-Pakete',
    NO_LAND_PACKAGES_MESSAGE: 'Keine Land-Pakete verfügbar',

    // Translations for LandPackageCard component
    ACCOMMODATION_LABEL: 'Unterkunft',
    TRANSFERS_LABEL: 'Transfers',
    HOTELS_LABEL: 'Hotels',
    ACTIVITIES_LABEL: 'Aktivitäten',
    PRICE_LABEL: 'Preis',
    NO_IMAGE_AVAILABLE: 'Kein Bild verfügbar',

    // Translations for FAQs component
    FAQ_SECTION_TITLE: 'Häufig gestellte Fragen',
    EXPAND_ICON_LABEL: 'Erweitern', // For accessibility (optional)

    // Translations for Footer component
    CALL_NOW_LABEL: 'Jetzt anrufen',
    TIME_OPERATIONAL_LABEL: 'Betriebszeit',
    LOCATION_LABEL: 'Standort',
    LOCATION_COUNTRY: 'Deutschland',
    FOOTER_DESCRIPTION: 'HalalFly bietet herausragende Hajj- und Umrah-Reiseerlebnisse und sorgt für eine reibungslose Reise mit hochwertigen Dienstleistungen, die auf Ihre Bedürfnisse zugeschnitten sind.',
    QUICK_LINKS_TITLE: 'Schnellzugriff',
    PRIVACY_POLICY: 'Datenschutzrichtlinie',
    TERMS_AND_CONDITIONS_LINK: 'Allgemeine Geschäftsbedingungen',
    OWNERS_DETAILS: 'Eigentümer Details',
    FAQS_LINK: 'FAQs',
    BLOG_LINK: 'Blog',
    NEWSLETTER_TITLE: 'Abonnieren Sie unseren Newsletter',
    NEWSLETTER_DESCRIPTION: 'Bleiben Sie auf dem Laufenden über unsere neuesten Angebote und Dienstleistungen.',
    NEWSLETTER_PLACEHOLDER: 'Geben Sie Ihre E-Mail-Adresse ein',
    SUBSCRIBE_BUTTON: 'JETZT ABONNIEREN',
    COPYRIGHT_TEXT: '© Copyright 2023. Alle Rechte vorbehalten. Entworfen von Halal Fly',

    // Umrah Package Card details
    TITLE: 'Umrah-Paketdetails',
    NONE:"Keine",
    NO_PACKAGES_AVAILABLE: 'Keine Pakete verfügbar',
    PACKAGE_NAME: 'Paketname',
    DESCRIPTION: 'Beschreibung',
    SELECT_DATE: 'Zeitraum auswählen',
    SELECT_ROOM: 'Zimmertyp auswählen',
    ADULTS: 'Erwachsene',
    INFANTS: 'Kleinkinder',
    TOTAL_PRICE: 'Gesamtpreis',
    FINAL_PRICE: '€',
    PRICE_NOTE: '*Preis kann je nach Verfügbarkeit variieren.',
    PROCEED_TO_BOOK: 'Zur Buchung fortfahren',
    SELECT_MEAL_PLAN: 'Verpflegungsplan auswählen',
    ACTIVITY_INSURANCE: 'Aktivitätsversicherung',
    TRANSFER_INSURANCE: 'Transfer-Versicherung',
    TRAVEL_INSURANCE: 'Reiseversicherung',
    FLIGHT_INSURANCE: 'Flugversicherung',
    ADD_INSURANCE: 'Versicherung hinzufügen',
    REMOVE_INSURANCE: 'Versicherung entfernen',
    SELECT_INSURANCE_TYPE: 'Versicherungstyp auswählen',
    SELECTED_INSURANCES: 'Ausgewählte Versicherungen',
    COUPON_CODE: 'Gutscheincode',
    COUPON_APPLIED:"Gutschein angewendet! Sie haben gespart",
    COUPON_ERROR:"Ungültiger Gutscheincode. Bitte versuchen Sie es erneut.",
    ENTER_YOUR_COUPON_CODE:"Geben Sie Ihren Gutscheincode ein",
    APPLY_DISCOUNT: 'Rabatt anwenden',
    APPLY:"Anwenden",
    PER_PERSON:"pro Erwachsener",
    INCLUDED_IN_SERVICE:"Was in Ihrem Umrah-Paket enthalten ist",
    INCLUSION: 'Inklusive Leistungen',
    EXCLUSION: 'Ausschlüsse',
    JOURNEY_INCLUSIONS: 'Diese Inklusivleistungen sind Teil Ihrer Reise und bieten Ihnen ein nahtloses und unvergessliches Umrah-Erlebnis.',
    INCLUDED_SERVICES: 'Inklusive Dienstleistungen',
    SERVICES_EXCLUDED: 'Ausgeschlossene Dienstleistungen',
    // Sections
    ROOM_SELECTION: 'Zimmerauswahl',
    ADDITIONAL_OPTIONS: 'Zusätzliche Optionen',
    TOTAL_PRICE_SECTION: 'Gesamtpreis',
    DETAILS_SECTION: 'Paketdetails',
    COUPONS_SECTION: 'Gutscheine anwenden',

  // FlightDetailsSection translations
  FLIGHT_INCLUDED_TITLE: 'Ihr Flug ist inbegriffen',
  FLIGHT_SELECTION_MESSAGE: 'Wir haben die beste verfügbare Flugoption aus {flightDetails.length} Alternativen ausgewählt.',
  FLIGHT_IMAGE_TITLE: 'Flugbild',
  DEPARTURE_LABEL: 'Abflughafen',
  ARRIVAL_LABEL: 'Ankunft',

// Hotel Details Section

HOTEL_TITLE: 'Hotels in', // Used in "Hotels in {hotel.city}: {hotel.HotelNames}"
VIEW_ALL_PHOTOS_BUTTON: 'Alle Fotos anzeigen', // Text for "View all photos" button in German
HOTEL_IMAGE_DIALOG_TITLE: 'Hotel Bild', // Title for the image dialog modal

    // Error Messages
    SELECT_ROOM_ERROR: 'Bitte wählen Sie einen Zimmertyp aus, bevor Sie Erwachsene oder Kleinkinder hinzufügen.',
    ADULT_LIMIT_ERROR: 'Sie können nicht mehr Erwachsene hinzufügen, als in diesem Zimmertyp erlaubt.',
    INFANT_LIMIT_ERROR: 'Sie können nicht mehr Kleinkinder hinzufügen, als in diesem Zimmertyp erlaubt.',

    // Translations for ContactUs component
    CONTACT_US_HEADING: 'Kontaktieren Sie uns',
    CONTACT_US_FORM_OPTION: 'Kontaktformular',
    BUSINESS_COLLABORATION_FORM_OPTION: 'Kooperationsformular',







    // Translations for ContactUsForm component
    CONTACT_US_FORM_TITLE: 'Kontaktformular',
    FULL_NAME_LABEL: 'Vollständiger Name',
    EMAIL_LABEL: 'E-Mail-Adresse',
    PHONE_LABEL: 'Telefonnummer',
    PACKAGE_TYPE_LABEL: 'Pakettyp',
    UMRAH_PACKAGE_OPTION: 'Umrah-Paket',
    LAND_PACKAGE_OPTION: 'Land-Paket',
    NUM_TRAVELERS_LABEL: 'Anzahl der Reisenden',
    ARRIVAL_DATE_LABEL: 'Ankunftsdatum',
    DEPARTURE_DATE_LABEL: 'Abreisedatum',
    FLIGHT_TICKETS_LABEL: 'Haben Sie bereits Flugtickets?',
    YES_OPTION: 'Ja',
    NO_OPTION: 'Nein',
    SUBMIT_BUTTON_TEXT: 'Anfrage absenden',

    // LandPackageCardDetails
    TITLE: 'Beliebte Land-Pakete',
    NO_PACKAGES_AVAILABLE: 'Keine Land-Pakete verfügbar',
    PACKAGE_NAME: 'Paketname',
    DESCRIPTION: 'Beschreibung',
    SELECT_DATE: 'Datum auswählen',
    SELECT_ROOM: 'Zimmertyp auswählen',
    ADULTS: 'Erwachsene',
    INFANTS: 'Kleinkinder',
    TOTAL_PRICE: 'Gesamtpreis',
    FINAL_PRICE: '€',
    PRICE_NOTE: '*Preis kann je nach Verfügbarkeit variieren.',
    PROCEED_TO_BOOK: 'Zur Buchung fortfahren',
    SELECT_MEAL_PLAN: 'Verpflegungsplan auswählen',
    ACTIVITY_INSURANCE: 'Aktivitätsversicherung',
    TRANSFER_INSURANCE: 'Transfer-Versicherung',
    TRAVEL_INSURANCE: 'Reiseversicherung',
    FLIGHT_INSURANCE: 'Flugversicherung',
    ADD_INSURANCE: 'Versicherung hinzufügen',
    REMOVE_INSURANCE: 'Versicherung entfernen',
    SELECT_INSURANCE_TYPE: 'Versicherungstyp auswählen',
    SELECTED_INSURANCES: 'Ausgewählte Versicherungen',
    COUPON_CODE: 'Gutscheincode',
    APPLY_DISCOUNT: 'Rabatt anwenden',
    INCLUSION: 'Inklusive Leistungen',
    EXCLUSION: 'Ausschlüsse',
    INCLUDED_SERVICES: 'Inklusive Dienstleistungen',
    SELECT_OCCUPANTS:"Insassen auswählen",
    // Additional Notes and Options
    ADDITIONAL_OPTIONS: 'Zusätzliche Optionen',
    ROOMS_AND_OCCUPANTS: 'Zimmer und Insassen',
    // Error Messages
    SELECT_ROOM_ERROR: 'Bitte wählen Sie einen Zimmertyp aus, bevor Sie Erwachsene oder Kleinkinder hinzufügen.',
    ADULT_LIMIT_ERROR: 'Sie können nicht mehr Erwachsene hinzufügen, als',
    // adiitional options
    // Titles and Labels
  NEED_ACTIVITY_OPTIONS: 'Benötigen Sie Aktivitätsoptionen?',
  ACTIVITY_OPTIONS_TITLE: 'Aktivitätsoptionen:',
  NONE: 'Keine',
  SELECT_ACTIVITY: 'Aktivität auswählen',

  NEED_TRANSFER_OPTIONS: 'Benötigen Sie Transferoptionen?',
  TRANSFER_OPTIONS_TITLE: 'Transferoptionen:',
  SELECT_TRANSFER: 'Transfer auswählen',

  NEED_TRAVEL_INSURANCE: 'Benötigen Sie eine Reiseversicherung?',
  TRAVEL_INSURANCE_TITLE: 'Reiseversicherung:',

  // Dialog Titles
  INSURANCE_DETAILS_DIALOG_TITLE: 'Versicherungsdetails',
  INSURANCE_SELECTION_DIALOG_TITLE: 'Versicherungstyp auswählen',

  // Insurance Section
  SELECT_INSURANCE_TYPE: 'Versicherungstyp auswählen',
  SELECTED_INSURANCE_TYPES: 'Ausgewählte Versicherungsarten:',
  NO_INSURANCE_SELECTED: 'Keine Versicherungsarten ausgewählt.',
  ADD_INSURANCE_BUTTON: 'Versicherung hinzufügen',
  VIEW_INSURANCE_DETAILS_TOOLTIP: 'Details anzeigen',
  REMOVE_INSURANCE_TOOLTIP: 'Versicherung entfernen',

  // Activity Options Section
  NO_ACTIVITY_AVAILABLE: 'Derzeit sind keine Aktivitätsoptionen verfügbar.',
  
  // Transfer Options Section
  NO_TRANSFER_AVAILABLE: 'Derzeit sind keine Transferoptionen verfügbar.',

  // Travel Insurance Options
  SELECT_INSURANCE_OPTION_YES: 'Ja',
  SELECT_INSURANCE_OPTION_NO: 'Nein',

  // Dialog Content
  INSURANCE_NO_DETAILS: 'Keine Details für diesen Versicherungstyp verfügbar.',
  ADD_SELECTED_INSURANCE: 'Hinzufügen',
  CANCEL_BUTTON: 'Abbrechen',
  CLOSE_BUTTON: 'Schließen',

  // Insurance Selection Dialog Actions
  CONFIRM_ADD_INSURANCE: 'Hinzufügen',
  CANCEL_SELECTION: 'Abbrechen',
  
  // Tooltips
  INSURANCE_INFO_TOOLTIP: 'Details anzeigen',
  REMOVE_INSURANCE_INFO_TOOLTIP: 'Versicherung entfernen',

  // Errors and Validation Messages
  SELECT_INSURANCE_REQUIRED: 'Bitte wählen Sie einen Versicherungstyp aus.',

    //Booking Details
        
          TITLE: "Buchungsformular",
          TOTAL_PRICE: "Gesamtpreis",
          DEPOSIT: "Anzahlung",
          PACKAGE: "Package",
          VISA_INFO: "Visainformationen",
          PAY_WITH: "Zahlen mit",
          BOOKING_MANAGER_DETAILS: "Buchungsmanager Details",
          NAME: "Name",
          PHONE: "Telefonnummer",
          EMAIL: "E-Mail-Adresse",
          NATIONALITY: "Nationalität",
          ADULTS_DETAILS: "Erwachsene Details",
          FULL_NAME: "Vollständiger Name",
          PASSPORT_NUMBER: "Reisepassnummer",
          VISA_TYPE: "Visumtyp",
          NATIONALITY_REQUIRED: "Nationalität ist erforderlich",
          SELECT_COUNTRY: "Wählen Sie Ihre Nationalität",
          VISA_DESCRIPTION: "Visa-Beschreibung",
          VISA_PRICE: "Preis",
          SELECT_VISA_TYPE: "Visumtyp auswählen",
          TOURISM_VISA: "Touristenvisum",
          UMRAH_VISA: "Umrah-Visum",
          HAVE_VISA: "Ich habe mein Visum",
          ADDITIONAL_FEE: "Zusätzliche Gebühr fällt an",
          INFO_BUTTON: "Visainformation",
          ERROR_NAME_REQUIRED: "Name ist erforderlich",
          ERROR_PASSPORT_REQUIRED: "Reisepassnummer ist erforderlich",
          ERROR_VISA_REQUIRED: "Visumtyp ist erforderlich",
          INFANTS_DETAILS: "Details zu Babys",
          PREFERRED_CONTACT_METHOD: "Bevorzugte Kontaktmethode",
          CONTACT_VIA: "Kontakt über",
          WHATSAPP: "WhatsApp",
          EMAIL: "E-Mail",
          PHONE: "Telefon",
          VISA_INFO_MODAL_TITLE: "Visainformation",
          VISA_INFO_MODAL_DESCRIPTION: "Detaillierte Beschreibung der Visabestimmungen.",
          CLOSE_BUTTON: "Schließen",
          PAYMENT_OPTIONS: "Zahlungsoptionen",
          PAYMENT_METHOD: "Zahlungsmethode",
          SELECT_PAYMENT_METHOD: "Zahlungsmethode auswählen",
          FULL_PAYMENT: "Vollständige Zahlung",
          PARTIAL_PAYMENT: "Teilzahlung",
          STRIPE: "Stripe",
          PAYPAL: "PayPal",
          PROCESSING_PAYMENT: "Zahlung wird verarbeitet",
          PAY_NOW: "Jetzt zahlen",
          PAY_DEPOSIT: "Anzahlung leisten",
          PAYMENT_CONFIRMATION: "Zahlungsbestätigung",
          PAYMENT_METHOD_REQUIRED: "Bitte wählen Sie eine Zahlungsmethode aus.",
          PAYMENT_PROCESSING: "Zahlung wird verarbeitet, bitte warten...",
          PAYMENT_FAILED: "Zahlung fehlgeschlagen, bitte versuchen Sie es erneut.",
          SUBMIT_SUCCESS: "Formular erfolgreich eingereicht",
          SUBMIT_ERROR: "Bitte beheben Sie die Validierungsfehler.",
          FORM_TITLE: "Kontaktieren Sie uns",
          PARTNERSHIP_REQUEST: "B2B-Partnerschaftsanfrage",
          ORGANIZATION_INFO: "Organisationsinformationen",
          ORGANIZATION_NAME: "Organisationsname",
          ORGANIZATION_TYPE: "Art der Organisation",
          ORGANIZATION_ADDRESS: "Organisationsadresse",
          CONTACT_NAME: "Kontaktname",
          EMAIL: "E-Mail",
          MOBILE_NUMBER: "Mobilnummer",
          TYPE_TRAVEL_AGENCY: "Reiseagentur",
          TYPE_CORPORATE: "Unternehmen",
          TYPE_NGO: "Moschee",
          RESERVATION_DETAILS: "Reservierungsdetails",
          DEPARTURE_CITY: "Abflugshafen",
          SELECT_PACKAGE: "Package auswählen",
          UMRAH_PACKAGES_HEADER: "Umrah-Package",
          LAND_PACKAGES_HEADER: "Land-Package",
          TOTAL_DAYS: "Reisedauer",
          NUM_PILGRIMS: "Anzahl der Pilger",
          VISA_REQUIRED: "Visum erforderlich",
          VISA_OPTION_YES: "Ja",
          VISA_OPTION_NO: "Nein",
          CONSENT: "Ich stimme den Bedingungen zu",
          SUBMIT_BUTTON: "Einreichen",
          REQUIRED_FIELD: "Dieses Feld ist erforderlich",
        
      
        //Buisness Collaboration Form
        FORM_TITLE: 'Kontaktieren Sie uns',
  PARTNERSHIP_REQUEST: 'B2B-Partnerschaftsanfrage',
  // Organization Information Section
  ORGANIZATION_INFO: 'Organisationsinformationen',
  ORGANIZATION_NAME: 'Organisationsname',
  ORGANIZATION_TYPE: 'Art der Organisation',
  ORGANIZATION_ADDRESS: 'Organisationsadresse',
  CONTACT_NAME: 'Kontaktname',
  EMAIL: 'E-Mail',
  MOBILE_NUMBER: 'Mobilnummer',
  // Organization Type Options
  TYPE_TRAVEL_AGENCY: 'Reiseagentur',
  TYPE_CORPORATE: 'Unternehmen',
  TYPE_NGO: 'NGO',
  // Reservation Details Section
  RESERVATION_DETAILS: 'Reservierungsdetails',
  DEPARTURE_CITY: 'Abflugstadt',
  SELECT_PACKAGE: 'Package auswählen',
  // Umrah & Land Packages Headers
  UMRAH_PACKAGES_HEADER: 'Umrah-Packages',
  LAND_PACKAGES_HEADER: 'Land-Packages',
  TOTAL_DAYS: 'Gesamttage',
  NUM_PILGRIMS: 'Anzahl der Pilger',
  VISA_REQUIRED: 'Visum erforderlich',
  VISA_OPTION_YES: 'Ja',
  VISA_OPTION_NO: 'Nein',
  SELECT_DATE: "Datum auswählen",
  // Consent and Submission Section
  CONSENT: 'Ich stimme den Bedingungen zu',
  SUBMIT_BUTTON: 'Einreichen',
  // Errors and Validation Messages
  REQUIRED_FIELD: 'Dieses Feld ist erforderlich',
      
    //Privacy Policy 
    TITLE: "Datenschutzrichtlinie für Halal Fly",
    EFFECTIVE_DATE: "Gültig ab: 11/29/2024",
    INTRODUCTION: "Bei Halal Fly legen wir großen Wert auf den Schutz Ihrer Privatsphäre und die Sicherheit Ihrer persönlichen Daten. Diese Datenschutzrichtlinie erklärt, wie wir Ihre persönlichen Daten erfassen, verwenden und schützen, wenn Sie unsere Website besuchen oder unsere Dienstleistungen in Anspruch nehmen.",
    INFORMATION_WE_COLLECT: "1. Informationen, die wir erfassen",
    INFORMATION_WE_COLLECT_DESCRIPTION: "Wir erfassen die folgenden Arten von Informationen von unseren Nutzern:",
    PERSONAL_INFORMATION: "a. Persönliche Informationen",
    PERSONAL_INFORMATION_DESCRIPTION: "Wenn Sie eine Buchung vornehmen, ein Konto registrieren oder uns über unsere Website kontaktieren, können wir persönliche Informationen erfassen, einschließlich, aber nicht beschränkt auf:",
    FULL_NAME: "Vollständiger Name",
    EMAIL_ADDRESS: "E-Mail-Adresse",
    PHONE_NUMBER: "Telefonnummer",
    PASSPORT_DETAILS: "Passdetails (für Buchungs- und Visa-Zwecke)",
    PAYMENT_INFORMATION: "Zahlungsinformationen",
    TRAVEL_DETAILS: "Reisepräferenzen und Buchungsdetails",
    NON_PERSONAL_INFORMATION: "b. Nicht-persönliche Informationen",
    NON_PERSONAL_INFORMATION_DESCRIPTION: "Wir können auch nicht-persönliche Informationen über Ihre Interaktionen mit unserer Website sammeln, wie z. B. Browsertyp, Geräteinformationen und IP-Adresse.",
    HOW_WE_USE_YOUR_INFORMATION: "2. Wie wir Ihre Informationen verwenden",
    HOW_WE_USE_YOUR_INFORMATION_DESCRIPTION: "Wir verwenden die erfassten Informationen für folgende Zwecke:",
    PURPOSES_LIST: "- Zur Abwicklung von Buchungen und Bereitstellung von Dienstleistungen.\n- Zur Kommunikation mit Ihnen bezüglich Ihrer Anfragen oder Buchungen.\n- Zur Verbesserung unserer Dienstleistungen.\n- Zur Einhaltung gesetzlicher Vorgaben, wenn erforderlich.",
    HOW_WE_SHARE_YOUR_INFORMATION: "3. Wie wir Ihre Informationen weitergeben",
    HOW_WE_SHARE_YOUR_INFORMATION_DESCRIPTION: "Wir können Ihre persönlichen Informationen mit vertrauenswürdigen Drittanbietern wie Dienstleistern (z. B. Fluggesellschaften, Hotels) oder in Fällen, die gesetzlich vorgeschrieben sind, teilen.",
    DATA_SECURITY: "4. Datensicherheit",
    DATA_SECURITY_DESCRIPTION: "Wir ergreifen Sicherheitsmaßnahmen, um Ihre Daten zu schützen, aber kein Online-Dienst ist zu 100% sicher. Wir empfehlen Ihnen, Ihre eigenen Vorsichtsmaßnahmen zu treffen.",
    COOKIES_AND_TRACKING: "5. Cookies und Tracking-Technologien",
    COOKIES_AND_TRACKING_DESCRIPTION: "Wir verwenden Cookies, um das Benutzererlebnis zu verbessern. Sie können Cookies über Ihre Browsereinstellungen steuern, aber das Deaktivieren von Cookies kann die Funktionalität beeinträchtigen.",
    YOUR_DATA_RIGHTS: "6. Ihre Rechte bezüglich Ihrer Daten",
    YOUR_DATA_RIGHTS_DESCRIPTION: "Sie haben das Recht, auf Ihre persönlichen Daten zuzugreifen, sie zu korrigieren, zu löschen oder deren Verwendung einzuschränken. Kontaktieren Sie uns, um diese Rechte auszuüben.",
    DATA_RETENTION: "7. Datenspeicherung",
    DATA_RETENTION_DESCRIPTION: "Wir bewahren Ihre Informationen so lange auf, wie es notwendig ist, um unsere Dienstleistungen zu erfüllen oder wie es gesetzlich vorgeschrieben ist.",
    CHANGES_TO_POLICY: "8. Änderungen an dieser Datenschutzrichtlinie",
    CHANGES_TO_POLICY_DESCRIPTION: "Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Alle Änderungen werden auf dieser Seite veröffentlicht, und wir empfehlen Ihnen, diese regelmäßig zu überprüfen.",
    CONTACT_US: "9. Kontaktieren Sie uns",
    CONTACT_US_DESCRIPTION: "Wenn Sie Fragen oder Bedenken haben, kontaktieren Sie uns bitte unter:\nE-Mail: [Insert Email]\nTelefon: [Insert Phone]\nAdresse: [Insert Address]",

    //Terms And Conitions
    TERMS_AND_CONDITIONS_TITLE: "Allgemeine Geschäftsbedingungen",
    EFFECTIVE_DATE: "Gültigkeitsdatum: [Einfügen Datum]",
    INTRODUCTION_TERMS_AND_CONDITIONS: "Willkommen bei Halal Fly. Diese Allgemeinen Geschäftsbedingungen regeln die Nutzung unserer Website und Dienstleistungen. Bitte lesen Sie sie sorgfältig durch, bevor Sie unsere Dienste nutzen.",
    
    ACCEPTANCE_OF_TERMS: "Annahme der Bedingungen",
    ACCEPTANCE_OF_TERMS_DESCRIPTION: "Durch den Zugriff auf unsere Website und die Nutzung unserer Dienste stimmen Sie den folgenden Bedingungen zu. Wenn Sie mit diesen Bedingungen nicht einverstanden sind, dürfen Sie unsere Dienste nicht nutzen.",
  
    USE_OF_SERVICES: "Nutzung der Dienste",
    USE_OF_SERVICES_DESCRIPTION: "Unsere Dienstleistungen stehen ausschließlich für rechtmäßige Zwecke zur Verfügung. Sie erklären sich damit einverstanden, unsere Dienstleistungen nicht für illegale oder unbefugte Zwecke zu verwenden.",
  
    USER_OBLIGATIONS: "Pflichten des Nutzers",
    USER_OBLIGATIONS_DESCRIPTION: "Als Nutzer unserer Website sind Sie verantwortlich für die Richtigkeit der von Ihnen bereitgestellten Informationen und die Einhaltung aller geltenden Gesetze bei der Nutzung unserer Dienste.",
  
    BOOKING_PROCESS: "Buchungsprozess",
    BOOKING_PROCESS_DESCRIPTION: "Alle Buchungen, die über unsere Website erfolgen, unterliegen der Verfügbarkeit und müssen von uns bestätigt werden. Eine Buchung gilt als abgeschlossen, wenn Sie eine Bestätigung von uns erhalten haben.",
  
    PAYMENTS: "Zahlungen",
    PAYMENTS_DESCRIPTION: "Zahlungen für unsere Dienstleistungen müssen im Voraus erfolgen, sofern nicht anders vereinbart. Wir akzeptieren verschiedene Zahlungsmethoden, die auf unserer Website angegeben sind.",
  
    CANCELLATION_POLICY: "Stornierungsbedingungen",
    CANCELLATION_POLICY_DESCRIPTION: "Stornierungen müssen gemäß den Stornierungsrichtlinien erfolgen, die auf unserer Website oder in der Buchungsbestätigung angegeben sind. Rückerstattungen werden gemäß unserer Rückerstattungsrichtlinie bearbeitet.",
  
    LIMITATION_OF_LIABILITY: "Haftungsbeschränkung",
    LIMITATION_OF_LIABILITY_DESCRIPTION: "Halal Fly haftet nicht für Verluste oder Schäden, die durch die Nutzung unserer Website oder Dienstleistungen entstehen, außer in Fällen von grober Fahrlässigkeit oder Vorsatz.",
  
    CHANGES_TO_TERMS: "Änderungen der Bedingungen",
    CHANGES_TO_TERMS_DESCRIPTION: "Halal Fly behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern. Alle Änderungen werden auf unserer Website veröffentlicht, und es liegt in Ihrer Verantwortung, die Bedingungen regelmäßig zu überprüfen.",
  
    CONTACT_US: "Kontaktieren Sie uns",
    CONTACT_US_DESCRIPTION: "Wenn Sie Fragen oder Bedenken zu diesen Allgemeinen Geschäftsbedingungen haben, kontaktieren Sie uns bitte unter support@halalfly.com.",
    USER_RESPONSIBILITIES: 'Verantwortlichkeiten des Benutzers',
    USER_RESPONSIBILITIES_DESCRIPTION: 'Als Nutzer sind Sie dafür verantwortlich, korrekte und aktuelle Informationen bereitzustellen, unsere Dienste rechtmäßig zu nutzen und keine schädlichen Aktivitäten durchzuführen. Sie erklären sich damit einverstanden, unsere Plattform nicht zu missbrauchen oder in einer Weise zu nutzen, die den Betrieb unserer Dienste beeinträchtigt.',
    
    PAYMENT_TERMS: 'Zahlungsbedingungen',
    PAYMENT_TERMS_DESCRIPTION: 'Alle Zahlungen für unsere Dienstleistungen müssen rechtzeitig und vollständig geleistet werden. Wir behalten uns das Recht vor, Gebühren, Steuern oder andere Beträge zu ändern oder hinzuzufügen, die für unsere Dienstleistungen anfallen können.',
    
    MODIFICATION_OF_TERMS: 'Änderungen der Bedingungen',
    MODIFICATION_OF_TERMS_DESCRIPTION: 'Wir behalten uns das Recht vor, diese Bedingungen jederzeit zu ändern. Solche Änderungen treten sofort nach ihrer Veröffentlichung auf unserer Website in Kraft. Es liegt in Ihrer Verantwortung, diese Bedingungen regelmäßig zu überprüfen.',
    
    TERMINATION_OF_SERVICE: 'Beendigung des Dienstes',
    TERMINATION_OF_SERVICE_DESCRIPTION: 'Wir können Ihren Zugang zu unseren Diensten jederzeit und ohne Vorankündigung kündigen, wenn Sie gegen diese Bedingungen verstoßen oder wir dies aus rechtlichen oder betrieblichen Gründen für notwendig erachten.',
    
    GOVERNING_LAW: 'Anwendbares Recht',
    GOVERNING_LAW_DESCRIPTION: 'Diese Bedingungen unterliegen den Gesetzen des Landes, in dem HalalFly tätig ist. Alle rechtlichen Streitigkeiten oder Ansprüche im Zusammenhang mit diesen Bedingungen werden in der Gerichtsbarkeit des zuständigen Landes behandelt.',
    
    CONTACT_INFORMATION: 'Kontaktinformationen',
    CONTACT_INFORMATION_DESCRIPTION: 'Wenn Sie Fragen oder Bedenken in Bezug auf diese Bedingungen haben, können Sie uns unter der folgenden E-Mail-Adresse kontaktieren: support@halalfly.com.',
    
    //OwnersDetails
    OWNER_DETAILS_TITLE: 'Eigentümer Details',
  BUSINESS_NAME: 'Firmenname',
  EMAIL_LABEL: 'E-Mail',
  PHONE_LABEL: 'Telefonnummer',
  LOCATION_LABEL: 'Standort',

//BOOkingdetails

  BOOKING_TITLE: 'Buchungsformular',
  GENERAL_ERROR_MESSAGE: 'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.',

  BOOKING_MANAGER_DETAILS_TITLE: 'Buchungsmanager Details',
  ADULTS_DETAILS_TITLE: 'Erwachsene Details',
  INFANTS_DETAILS_TITLE: 'Babys Details',
  PREFERRED_CONTACT_METHOD_TITLE: 'Bevorzugte Kontaktmethode',

  DIVIDER_COLOR: '#FF8C42',

  TOTAL_PRICE_LABEL: 'Gesamtpreis',
  DEPOSIT_LABEL: 'Anzahlung',
  PRICE_FORMAT: '€',
  PACKAGE_LABEL: 'Paket',
  PAYMENT_OPTIONS_TITLE: 'Zahlungsmöglichkeiten',
  PAY_BUTTON_TEXT: 'Bezahlen mit',
  PROCESSING_TEXT: 'Verarbeitung...',
  PAY_BUTTON_COLOR: '#004e8c',
  PAY_BUTTON_HOVER_COLOR: '#00376b',
  PAY_BUTTON_ACTIVE_COLOR: '#002a56',
  
  // Typography translations
  TOTAL_PRICE: 'Gesamtpreis',
  DEPOSIT: 'Anzahlung',
  PACKAGE: 'Paket',
  
  // Additional button options
  PAY_WITH_TEXT: 'Bezahlen mit',

};
//Failure payment translation
export const FailurePaymentTranslation = {
  title: 'Zahlungsproblem erkannt',
  message: 'Es scheint ein Problem bei der Verarbeitung Ihrer Zahlung über PayPal aufgetreten zu sein. Dies kann auf ein temporäres Problem auf deren Seite zurückzuführen sein.',
  suggestion: 'Bitte versuchen Sie es erneut oder wenden Sie sich an den PayPal-Support, falls das Problem weiterhin besteht. Ihre Transaktion wurde noch nicht abgeschlossen.',
  contactButton: 'PayPal kontaktieren'
};
// Success Payment translation
export const SuccessPaymentTranslation = {
  successTitle: 'Zahlung erfolgreich!',
  successMessage: 'Vielen Dank für Ihren Einkauf! Ihre Transaktion war erfolgreich, und Ihre Bestellung wird nun bearbeitet.',
  confirmationMessage: 'Eine Bestätigungs-E-Mail mit den Details Ihrer Bestellung wurde an Ihre E-Mail-Adresse gesendet.',
  orderNumber: 'Bestellnummer',
  viewDetailsButton: 'Bestelldetails anzeigen',
  hideDetailsButton: 'Bestelldetails ausblenden',
  receiptTitle: 'Bestellbeleg',
  contactUs: 'Kontaktieren Sie uns',
  paymentMethod: 'Zahlungsmethode',
  roomType: 'Zimmertyp',
  numberOfAdults: 'Anzahl der Erwachsenen',
  paymentStatus: 'Zahlungsstatus',
  dateCreated: 'Erstellungsdatum',
  printReceipt: 'Beleg drucken',
  downloadPDF: 'PDF herunterladen'
};





export default Translation_german;
