import React, { useState } from "react";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  useTheme,
  Typography,
  Divider,
} from "@mui/material";
import UmrahFilter from "./umrahFilter";
import Translation_german from "../Translation/translation_german";

function Index({ umrahIcon, landPackageIcon, allUmrahPackages, allLandPackages,allHalalTravelPackages,halalTravelPackageIconUrl }) {
  const [selectedTab, setSelectedTab] = useState(Translation_german.UMRAH_PACKAGES_LABEL);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event, newValue) => {
    if (newValue) setSelectedTab(newValue); // Prevent null tab selection
  };

  return (
    <Box
      sx={{
        p: isMobile ? 3 : 3,
        width: "100%",
        position: "relative",
        borderRadius: "20px",
        overflow: "hidden",
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Soft shadow
        backgroundColor: isMobile?"transparent":"#f9f9f9", // White background
        zIndex: 10,
        ml: isMobile ? 0 : "auto",
        mr: isMobile ? 0 : "auto", // Center align
        alignItems: "center",
        color: isMobile?'white':"#004e8c", // Blue for text and primary accents
      }}
    >
      <Typography
          sx={{
            color: isMobile ? "#FFFFFF" : "#004e8c",
            marginBottom:1
          }}
          variant={isMobile?"h3":'h2'}
          align="center"
        >
          Filtern Sie Ihr Traumangebot
        </Typography>
      {/* Segmented Control Section */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center", // Center content
          flexDirection: "row", // Row layout for all screen sizes
          alignItems: "center",
          mb: isMobile ? 2 : 3,
          gap: isMobile ? 1 : 2, // Maintain consistent spacing
        }}
      >
        <ToggleButtonGroup
          value={selectedTab}
          exclusive
          onChange={handleTabChange}
          aria-label="Umrah and Land Packages Toggle"
          sx={{
            display: "flex", // Ensure consistent layout
            justifyContent: "center", // Align buttons centrally
            gap: isMobile ? 1 : 2, // Add spacing between buttons
            "& .MuiToggleButton-root": {
              borderColor: "#f3c893",
              color: isMobile?"white":"#004e8c",
              textTransform: "capitalize",
              fontWeight: "bold",
              transition: "all 0.3s ease",
              padding: isMobile ? "6px 10px" : "8px 16px", // Adjust padding for mobile
              fontSize: isMobile ? "0.8rem" : "1rem", // Adjust font size
              "&.Mui-selected": {
                backgroundColor: isMobile?"#D5B782":"#004e8c",
                color: "#FFFFFF",
                "& .icon": {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "50%", // Circular icon
                  padding: "4px",
                },
              },
              "&:hover": {
                backgroundColor: isMobile?"transparent":"#f3c893",
                transform: "scale(1.05)",
                color: isMobile?"transparent":"#004e8c",
              },
              "&:focus": {
                boxShadow: "0 0 8px rgba(0, 78, 140, 0.2)",
              },
            },
          }}
        >
          <ToggleButton value={Translation_german.UMRAH_PACKAGES_LABEL} aria-label="Umrah">
            <Box
              component="img"
              src={umrahIcon}
              alt="Umrah Icon"
              loading="lazy"
              className="icon"
              sx={{
                width: isMobile ? 24 : 28, // Adjust icon size for mobile
                height: isMobile ? 24 : 28,
                backgroundColor:isMobile&&"white",
                mr: 1,
                transition: "all 0.3s ease",
              }}
            />
            {Translation_german.UMRAH_PACKAGES_LABEL}
          </ToggleButton>
          <ToggleButton value={Translation_german.LAND_PACKAGES_LABEL} aria-label="Land">
            <Box
              component="img"
              src={landPackageIcon}
              alt="Land Package Icon"
              loading="lazy"
              
              className="icon"
              sx={{
                width: isMobile ? 24 : 28,
                height: isMobile ? 24 : 28,
                mr: 1,
                backgroundColor:isMobile&&"white",

                transition: "all 0.3s ease",
              }}
            />
            {Translation_german.LAND_PACKAGES_LABEL}
          </ToggleButton>
          <ToggleButton value={"Halal Travel Packages"} aria-label="Halal Travel">
            <Box
              component="img"
              src={halalTravelPackageIconUrl}
              alt="Halal Travel Package Icon"
              loading="lazy"
              className="icon"
              sx={{
                width: isMobile ? 24 : 28,
                height: isMobile ? 24 : 28,
                backgroundColor:isMobile&&"white",
                mr: 1,
                transition: "all 0.3s ease",
              }}
            />
            Halal Travel Packages
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>


{   !isMobile&&   <Divider sx={{ my: 2, backgroundColor: "#004e8c", height: "2px" }} />
}
      {/* Render Selected Component */}
      <Box sx={{ mt: isMobile ? 2 : 3, px: isMobile ? 2 : 4 }}>
        <Typography variant="h6" align="center" gutterBottom sx={{ color: isMobile?"white":"#004e8c", fontWeight: "bold" }}>
          {selectedTab === Translation_german.UMRAH_PACKAGES_LABEL
            ? "Umrah-Pakete Filter"

            : selectedTab === Translation_german.LAND_PACKAGES_LABEL ?"Land-Pakete Filter"
          :
          "Halal Travel Packages Filter"
          }
        </Typography>
        {selectedTab === Translation_german.UMRAH_PACKAGES_LABEL ? (
            <UmrahFilter travelData={allUmrahPackages} />
          ) : selectedTab === Translation_german.LAND_PACKAGES_LABEL ? (
            <UmrahFilter travelData={allLandPackages} />
          ) : (
            <UmrahFilter travelData={allHalalTravelPackages} />
          )}
      </Box>
    </Box>
  );
}

export default Index;
