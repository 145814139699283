import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllHotelsApiReset,
  getHotelTravelCardsApiReset,
  getStaticHomeApiReset,
  getAllHalalTravelPackagesApiReset
} from "../../reducers";
import {
  getAllHotelsApi,
  getHotelTravelCardsApi,
  getStaticHomeApi,
  getHalalTravelPackages
} from "../../services";
import LoadingScreen from "../LoadingScreen";
import Filter from "../filter/index";
import UmrahHajjCarousel from "../UmrahPackages/UmrahPackages";
import HeaderAndFilterSection from "../HeaderAndFilterSection";
import FAQs from "../FAQs";
import Services from "../OurServices";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Suspense } from "react";
import WhyChooseUs from "../WhyChooseUs";

const Home = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [imagesLoaded, setImagesLoaded] = useState(false);

  const { data: allHotels } = useSelector((state) => state.getAllHotelsApiReducer);
  const { data: allUmrahPackages } = useSelector((state) => state.getHotelTravelCardsApiReducer);
  const { data: homePage } = useSelector((state) => state.getStaticHomeApiReducer);
  const { data: allHalalTravelPackages } = useSelector((state) => state.getAllHalalTravelPackagesApiReducer);
  const allPackages = [
    ...(allHotels?.map((pkg) => ({ ...pkg, packageType: "land" })) || []),
    ...(allUmrahPackages?.map((pkg) => ({ ...pkg, packageType: "umrah" })) || []),
    ...(allHalalTravelPackages?.map((pkg) => ({ ...pkg, packageType: "halalTravel" })) || []),
  ];
  useEffect(() => {
    dispatch(getAllHotelsApi());
    dispatch(getHotelTravelCardsApi());
    dispatch(getStaticHomeApi());
    dispatch(getHalalTravelPackages());

    return () => {
      dispatch(getAllHotelsApiReset());
      dispatch(getHotelTravelCardsApiReset());
      dispatch(getStaticHomeApiReset());
      dispatch(getAllHalalTravelPackagesApiReset());
    };
  }, [dispatch]);

  // Safeguard against missing data
  const mainImages = homePage?.[0]?.MainImage || [];
  const mainText = homePage?.[0]?.MainText || "";
  const umrahIconUrl = homePage?.[0]?.UmrahPackageIcon?.url || "";
  const landPackageIconUrl = homePage?.[0]?.LandPackageIcon?.url || "";
  const halalTravelPackageIconUrl = homePage?.[0]?.HalalTravelPackageIcon?.url || '';

  // Memoize props for Filter component
  const filterProps = useMemo(() => {
    return {
      umrahIcon: umrahIconUrl,
      landPackageIcon: landPackageIconUrl,
      halalTravelPackageIconUrl: halalTravelPackageIconUrl,
      allLandPackages: allHotels?.map((pkg) => ({ ...pkg, packageType: "land" })) || [],
      allUmrahPackages: allUmrahPackages?.map((pkg) => ({ ...pkg, packageType: "umrah" })) || [],
      allHalalTravelPackages: allHalalTravelPackages?.map((pkg) => ({ ...pkg, packageType: "halalTravel" })) || [],
    };
  }, [
    umrahIconUrl,
    landPackageIconUrl,
    halalTravelPackageIconUrl,
    allHotels,
    allUmrahPackages,
    allHalalTravelPackages,
  ]);
  

  // Carousel settings for react-slick
  const sliderSettings = {
    infinite: true,
    autoplay: true,
    speed: 1200, // Smooth transition duration
    autoplaySpeed: 3000, // 2 seconds for each slide
    arrows: false, // Keep the slider clean
    dots: false, // Add pagination dots to indicate progress
    fade: true, // Fade effect for a smoother transition
    pauseOnHover: false, // Pause on hover for user control
    cssEase: "ease-in-out", // Smooth easing for transitions
    adaptiveHeight: true, // Adjust height dynamically based on content
  };
  
  

  // Wait for images to load
  useEffect(() => {
    if (mainImages.length > 0) {
      const imagePromises = mainImages.map((img) => {
        return new Promise((resolve) => {
          const imgElement = new Image();
          imgElement.src = img.url;
          imgElement.onload = resolve;
          imgElement.onerror = resolve;
        });
      });

      Promise.all(imagePromises).then(() => {
        setImagesLoaded(true);
      });
    } else {
      setImagesLoaded(true); // No images to load
    }
  }, [mainImages]);

  if (!imagesLoaded) {
    return <LoadingScreen />; // Show loading screen while images are being loaded
  }

  return (
    <Grid container sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      {/* Hero Section with Carousel */}
      <div style={{ width: "100%", minHeight: isMobile ? "70vh" : "100vh" }}>
  <Slider {...sliderSettings}>
    {mainImages.map((image, index) => (
      <div key={index}>
        <Grid
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(12, 12, 12, 0.8), rgba(0, 78, 140, 0.8)), url(${image.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: isMobile ? "100vh" : "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
        
        >
          {!isMobile&&<HeaderAndFilterSection mainText={mainText} />}

        </Grid>
      </div>
    ))}
  </Slider>
  
  <Grid
        item
        container
        xs={12}
        md={10}
        justifyContent="center"
        sx={{
          marginTop: { xs: -87, sm: -17, md: -10 },
          marginX: "auto",
          paddingX: { xs: 1, sm: 2, md: 3 },
        }}
      >
        
       <Filter {...filterProps} />
      </Grid>
</div>


      {/* Filter Section */}
      {/* <Grid
        item
        container
        xs={12}
        md={10}
        justifyContent="center"
        sx={{
          marginTop: { xs: -53, sm: -17, md: -10 },
          marginX: "auto",
          paddingX: { xs: 1, sm: 2, md: 3 },
        }}
      >
       <Filter {...filterProps} />
      </Grid> */}

      {/* Our Services Section */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          marginTop:{xs:15},
          padding: { xs: 4, sm: 6, md: 8 },
          textAlign: "center",
          color: "#D5B782",
        }}
      >
        <Grid item xs={12} md={10}>
          <Services homePage={homePage?.[0] || {}} />
        </Grid>
       
      </Grid>

      {/* Features and Packages Section */}
      <Grid
        container
        sx={{
          background: "#FFFFFF",
          padding: { xs: 4, sm: 6, md: 8 },
          textAlign: "center",
          color: "#0C0C0C",
        }}
      >
        <Grid item xs={12}>
          <UmrahHajjCarousel homePage={homePage?.[0] || {}} packages={allPackages} />
        </Grid>
      
      </Grid>

      {/* FAQs Section */}
      <Suspense fallback={<LoadingScreen />}>
        <Grid
          container
          sx={{
            padding: { xs: 4, sm: 6, md: 8 },
            textAlign: "center",
          }}
        >
          <FAQs homePage={homePage?.[0] || {}} />
        </Grid>
      </Suspense>
    </Grid>
  );
};

export default Home;
