import React, { useState } from 'react';
import { useTheme, useMediaQuery, Box, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Translation_german from '../Translation/translation_german';

const SliderComponent = ({ images }) => {
  const [open, setOpen] = useState(false); // For controlling the modal
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // To track which image is being viewed
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const handleClickOpen = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Left: Large Image */}
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              position: 'relative',
              borderRadius: '12px',
              overflow: 'hidden',
              cursor: 'pointer',
              '& img': {
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              },
              '&:hover img': {
                transform: 'scale(1.05)',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
              },
            }}
            onClick={() => handleClickOpen(0)}
          >
            <img
              src={images[0]?.url}
              alt={images[0]?.name}
              style={{
                width: '100%',
                height: '500px',
                objectFit: 'cover',
                borderRadius: '12px',
              }}
            />
          </Box>
        </Grid>

        {/* Right: Smaller Images in Grid */}
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            {images.slice(1, 5).map((img, index) => (
              <Grid item xs={6} key={index}>
                <Box
                  sx={{
                    position: 'relative',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    '& img': {
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    },
                    '&:hover img': {
                      transform: 'scale(1.1)',
                      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => handleClickOpen(index + 1)}
                >
                  <img
                    src={img.url}
                    alt={img.name}
                    style={{
                      width: '100%',
                      height: '150px',
                      objectFit: 'cover',
                      borderRadius: '10px',
                    }}
                  />
                </Box>
              </Grid>
            ))}

            {/* View All Photos Overlay */}
            {images.length > 5 && (
              <Grid item xs={6}>
                <Box
                  sx={{
                    position: 'relative',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    '& img': {
                      transition: 'transform 0.3s ease',
                    },
                    '&:hover img': {
                      transform: 'scale(1.1)',
                    },
                  }}
                  onClick={() => handleClickOpen(5)}
                >
                  <img
                    src={images[5]?.url}
                    alt="View All"
                    style={{
                      width: '100%',
                      height: '150px',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      filter: 'brightness(50%)',
                    }}
                  />
                  <Typography
                    variant={isMobile ? 'body2' : 'h6'}
                    sx={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '10px',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  >
                    {Translation_german.VIEW_ALL_PHOTOS_BUTTON}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Full-Screen Image Modal */}
      {open && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
          }}
          onClick={handleClose}
        >
          <Box sx={{ position: 'relative', textAlign: 'center' }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: '#fff',
              }}
            >
              <CloseIcon sx={{ fontSize: '30px' }} />
            </IconButton>

            {currentImageIndex > 0 && (
              <IconButton
                aria-label="previous"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrevImage();
                }}
                sx={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#fff',
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            )}

            <img
              src={images[currentImageIndex]?.url}
              alt="Full-size preview"
              style={{
                maxWidth: '90%',
                maxHeight: '90vh',
                objectFit: 'contain',
                borderRadius: '8px',
              }}
            />

            {currentImageIndex < images.length - 1 && (
              <IconButton
                aria-label="next"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNextImage();
                }}
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#fff',
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: '30px' }} />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SliderComponent;
