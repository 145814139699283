import React, { useState } from 'react';
import { Box, Button, Grid, Typography, ToggleButtonGroup, ToggleButton, useTheme, useMediaQuery } from '@mui/material';
import { CreditCard } from '@mui/icons-material';
import paypal from '../../assets/images/paypal.png';

const PaymentOptions = ({ selectedMethod, setSelectedMethod, paymentType, setPaymentType }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectMethod = (method) => {
    setSelectedMethod(method); // Ausgewählte Zahlungsmethode festlegen
  };

  const handlePaymentTypeChange = (event, newType) => {
    setPaymentType(event.target.value);
  };

  return (
    <Box sx={{ textAlign: 'center', mt: 4, px: isMobile ? 2 : 0 }}>
      <Typography variant={isMobile ? 'h6' : 'h5'} gutterBottom>
        Wählen Sie eine Zahlungsmethode
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {/* Stripe Zahlungsoption */}
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              borderColor: selectedMethod === 'Stripe' ? '#004e8c' : '#ccc',
              backgroundColor: selectedMethod === 'Stripe' ? '#e0f7fa' : '#fff',
              color: selectedMethod === 'Stripe' ? '#004e8c' : '#000',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textTransform: 'none',
              padding: isMobile ? '10px' : '16px',
              '&:hover': { borderColor: '#004e8c', backgroundColor: '#e0f7fa' },
            }}
            onClick={() => handleSelectMethod('Stripe')}
          >
            <CreditCard sx={{ color: '#004e8c', fontSize: isMobile ? 20 : 50, mb: 1 }} />
            <Typography variant="button">Mit Debit-/Kreditkarte bezahlen</Typography>
          </Button>
        </Grid>

        {/* PayPal Zahlungsoption */}
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              borderColor: selectedMethod === 'PayPal' ? '#004e8c' : '#ccc',
              backgroundColor: selectedMethod === 'PayPal' ? '#e0f7fa' : '#fff',
              color: selectedMethod === 'PayPal' ? '#004e8c' : '#000',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textTransform: 'none',
              padding: isMobile ? '10px' : '16px',
              '&:hover': { borderColor: '#004e8c', backgroundColor: '#e0f7fa' },
            }}
            onClick={() => handleSelectMethod('PayPal')}
          >
            <Box
              component="img"
              src={paypal}
              alt="PayPal"
              sx={{ width: isMobile ? '20px' : '54px', mb: 1 }}
            />
            <Typography variant="button">Mit PayPal bezahlen</Typography>
          </Button>
        </Grid>
      </Grid>

      {/* Zeige vollständige Zahlung/Anzahlung nur, wenn eine Zahlungsmethode ausgewählt wurde */}
      {selectedMethod && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Wählen Sie die Zahlungsart
          </Typography>
          <ToggleButtonGroup
            value={paymentType}
            exclusive
            onChange={handlePaymentTypeChange}
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <ToggleButton value="full" sx={{
              flex: 1,
              textTransform: 'none',
              backgroundColor: paymentType === 'full' ? '#004e8c' : '#FFFFFF',
              color: paymentType === 'full' ? '#FFFFFF' : 'black',
              fontSize: isMobile ? '0.75rem' : '1rem',
              '&:hover': {
                backgroundColor: paymentType === 'full' ? '#00376b' : '#004e8c',
                color: paymentType === 'full' ? 'black' : 'white'
              }
            }}>
              Vollständige Zahlung
            </ToggleButton>
            <ToggleButton value="deposit" sx={{
              flex: 1,
              textTransform: 'none',
              backgroundColor: paymentType === 'deposit' ? '#004e8c' : '#FFFFFF',
              color: paymentType === 'deposit' ? '#FFFFFF' : 'black',
              fontSize: isMobile ? '0.75rem' : '1rem',
              '&:hover': {
                backgroundColor: paymentType === 'deposit' ? '#00376b' : '#004e8c',
                color: paymentType === 'deposit' ? 'black' : 'white'
              }
            }}>
              Anzahlung
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  );
};

export default PaymentOptions;
