import React, { useState } from 'react';
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Tooltip,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BedIcon from '@mui/icons-material/Bed';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const FilterComponent = ({ onFilterChange, filterOptions }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // States for Filters
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedRoomTypes, setSelectedRoomTypes] = useState([]);
  const [needInsurance, setNeedInsurance] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [needFlights, setNeedFlights] = useState(false);
  const [needActivity, setNeedActivity] = useState(false);
  const [needTransfer, setNeedTransfer] = useState(false);

  // Handle Filter Change
  const handleFilterChange = () => {
    const filters = {
      selectedCities,
      selectedRoomTypes,
      needInsurance,
      needFlights,
      needActivity,
      needTransfer,
      searchQuery,
      startDate,
      endDate,
      selectedPartners,
    };
    onFilterChange(filters);
  };

  // Reset Filters
  const resetFilters = () => {
    setSelectedCities([]);
    setSelectedRoomTypes([]);
    setNeedInsurance(false);
    setSearchQuery('');
    setStartDate(null);
    setEndDate(null);
    setSelectedPartners([]);
  };

  return (
    <Box
    sx={{
      backgroundColor: '#fff',
      borderRadius: 3,
      padding: 3,
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      position: isMobileScreen ? 'fixed' : 'sticky',
      top: 10, // Adjust the position relative to the viewport
      left: isMobileScreen ? 0 : 10,
      maxWidth: isMobileScreen ? '340px' : '400px',
      zIndex: 1000, // Ensure it stays on top
      overflowY: 'auto', // Handle scrolling for overflowing content
      maxHeight: '90vh', // Prevents the filter from exceeding the viewport height
      scrollbarWidth: 'thin', // Firefox scrollbar
      scrollbarColor: '#004e8c #f1f1f1', // Firefox scrollbar colors
      '&::-webkit-scrollbar': {
        width: '8px', // Width of the scrollbar
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#004e8c', // Scrollbar thumb color
        borderRadius: '4px', // Rounded corners
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#003366', // Scrollbar thumb hover color
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1', // Scrollbar track color
      },
      [theme.breakpoints.down('sm')]: {
        top: 0,
        width: '100%',
        borderRadius: 0,
        boxShadow: 'none',
      },
    }}
  >
      <Typography
        variant="h6"
        sx={{
          marginBottom: 3,
          fontWeight: 'bold',
          color: '#004e8c',
          fontSize: isMobileScreen ? '1rem' : '1.2rem',
          textAlign: 'center',
        }}
      >
        Filter Packages
      </Typography>

      {/* Selected Filters Preview */}
      <Box sx={{ marginBottom: 3, padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#004e8c' }}>
          Selected Filters:
        </Typography>
        {selectedCities.length > 0 && (
          <Typography variant="body2">Cities: {selectedCities.join(', ')}</Typography>
        )}
        {selectedRoomTypes.length > 0 && (
          <Typography variant="body2">Room Types: {selectedRoomTypes.join(', ')}</Typography>
        )}
        {startDate && endDate && (
          <Typography variant="body2">
            Date Range: {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
          </Typography>
        )}
        {selectedPartners.length > 0 && (
          <Typography variant="body2">Providers: {selectedPartners.join(', ')}</Typography>
        )}
      </Box>

      {/* Search Query */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography sx={{ marginBottom: 1, fontSize: '0.9rem', color: '#666' }}>
          Search
        </Typography>
        <TextField
          fullWidth
          placeholder="Search by package name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          sx={{
            backgroundColor: '#f9f9f9',
            borderRadius: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
            },
          }}
        />
      </Box>

      {/* Cities */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography sx={{ marginBottom: 1, fontSize: '0.9rem', color: '#666' }}>
          Departure City
        </Typography>
        <Autocomplete
          multiple
          options={filterOptions?.cities || []}
          value={selectedCities}
          onChange={(e, newValue) => setSelectedCities(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Cities"
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: <LocationCityIcon sx={{ color: '#004e8c', mr: 1 }} />,
              }}
            />
          )}
          sx={{
            backgroundColor: '#f9f9f9',
            borderRadius: 1,
            '& .MuiOutlinedInput-root': { borderRadius: 2 },
          }}
        />
      </Box>

      {/* Room Types */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography sx={{ marginBottom: 1, fontSize: '0.9rem', color: '#666' }}>
          Room Types
        </Typography>
        <Autocomplete
          multiple
          options={filterOptions?.roomTypes || []}
          value={selectedRoomTypes}
          onChange={(e, newValue) => setSelectedRoomTypes(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Room Types"
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: <BedIcon sx={{ color: '#FF8C42', mr: 1 }} />,
              }}
            />
          )}
          sx={{
            backgroundColor: '#f9f9f9',
            borderRadius: 1,
            '& .MuiOutlinedInput-root': { borderRadius: 2 },
          }}
        />
      </Box>

      {/* Date Range */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography sx={{ marginBottom: 1, fontSize: '0.9rem', color: '#666' }}>
          Date Range
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ marginBottom: 2 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} size="small" fullWidth />}
            />
          </Box>
          <Box>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} size="small" fullWidth />}
            />
          </Box>
        </LocalizationProvider>
      </Box>

      {/* Partners */}
     <Box sx={{ marginBottom: 3 }}>
      <Typography sx={{ marginBottom: 1, fontSize: '0.9rem', color: '#666' }}>
        Select Provider
      </Typography>
      <Autocomplete
        multiple
        options={filterOptions?.partners} // Filters out null values
        value={selectedPartners}
        onChange={(e, newValue) => setSelectedPartners(newValue)}
        getOptionLabel={(option) => option} // Since these are simple strings
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Providers"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: <PeopleAltIcon sx={{ color: '#FF8C42', mr: 1 }} />,
            }}
          />
        )}
        sx={{
          backgroundColor: '#f9f9f9',
          borderRadius: 1,
          '& .MuiOutlinedInput-root': { borderRadius: 2 },
        }}
      />
      </Box>



      {/* Need Insurance */}
      <Accordion sx={{ marginBottom: 3 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Advanced Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControlLabel
            control={
              <Checkbox
                checked={needInsurance}
                onChange={(e) => setNeedInsurance(e.target.checked)}
              />
            }
            label="Include Insurance"
          />
           <FormControlLabel
            control={
              <Checkbox
                checked={needFlights}
                onChange={(e) => setNeedFlights(e.target.checked)}
              />
            }
            label="Include Flights"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={needTransfer}
                onChange={(e) => setNeedTransfer(e.target.checked)}
              />
            }
            label="Include Transfer"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={needActivity}
                onChange={(e) => setNeedActivity(e.target.checked)}
              />
            }
            label="Include Activity"
          />
        </AccordionDetails>
      </Accordion>

      {/* Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          variant="outlined"
          onClick={resetFilters}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: '#004e8c',
            borderColor: '#004e8c',
            '&:hover': { backgroundColor: '#004e8c', color: '#fff' },
          }}
        >
          Reset Filters
        </Button>
        <Button
          variant="contained"
          onClick={handleFilterChange}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            backgroundColor: '#004e8c',
            color: '#fff',
            '&:hover': { backgroundColor: '#003366' },
          }}
        >
          Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default FilterComponent;
