import React, { useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {  useTheme } from "@mui/material";
import NavigationButton from '../NavigationButton';
import { getStaticHomeApi, getB2bPackagesApi, getHotelTravelCardsApi, getAllHotelsApi, getHalalTravelPackages } from '../../services';
import { getStaticHomeApiReset, getB2bPackagesApiReset, getAllHotelsApiReset, getHotelTravelCardsApiReset, getAllHalalTravelPackagesApiReset } from "../../reducers";
import LoadingScreen from '../LoadingScreen';

const useStyles = makeStyles((theme) => ({
  headerContainer:() => ({
    padding: '20px 20px',
    backgroundColor: '#004e8c',
    color: '#FAF3E0',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderBottom: '2px solid #FF8C42',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensure space between logo and menu
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', // Stack items on small screens
      padding: '20px 20px',
    },
  }),
  logo: {
    maxWidth: '80px',
    height: 'auto',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '60px', // Make logo smaller on small screens
      marginBottom: theme.spacing(2), // Add margin between logo and menu
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '60px', // Make logo smaller on small screens
      marginBottom: theme.spacing(2), // Add margin between logo and menu
    }
  },
  navButton: {
    padding: '10px 20px',
    background: '#FAF3E0',
    borderRadius: '10px',
    color: '#004e8c',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    border: '2px solid #FF8C42',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#FF8C42',
      color: '#FAF3E0',
      transform: 'scale(1.05)',
      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
}));

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation(); // Used to track the current route
  const dispatch = useDispatch();
  const classes = useStyles();

  const { data: homePage, loading: homePageLoading } = useSelector(
    (state) => state.getStaticHomeApiReducer
  );
  const { data: b2bPackages, loading: b2bPackagesLoading } = useSelector(
    (state) => state.getB2bPackagesApiReducer
  );
  const { data: allHalalPackages, loading: halalPackagesLoading } = useSelector(
    (state) => state.getAllHalalTravelPackagesApiReducer
  );
  useEffect(() => {
    dispatch(getStaticHomeApi());
    dispatch(getB2bPackagesApi());
    dispatch(getAllHotelsApi());
    dispatch(getHotelTravelCardsApi());
    dispatch(getHalalTravelPackages());

    return () => {
      dispatch(getAllHotelsApiReset());
      dispatch(getHotelTravelCardsApiReset());
      dispatch(getStaticHomeApiReset());
      dispatch(getB2bPackagesApiReset());
      dispatch(getAllHalalTravelPackagesApiReset());
    };
  }, [dispatch, location.pathname]); // Adding location.pathname ensures API is called on route change

  useEffect(() => {
    // Store the logo in local storage if it changes
    const logoUrl = homePage?.length > 0 && homePage[0].Logo?.url;
    if (logoUrl) {
      const storedLogo = localStorage.getItem('logo');
      if (storedLogo !== logoUrl) {
        localStorage.setItem('logo', logoUrl);
      }
    }
  }, [homePage]);

  // Get logo from local storage
  const logoUrl = localStorage.getItem('logo');

  const { data: allLandPackages } = useSelector(
    (state) => state.getAllHotelsApiReducer
  );
  const { data: allUmrahPackages } = useSelector(
    (state) => state.getHotelTravelCardsApiReducer
  );

  // If the API is still loading, display the LoadingScreen component
  if (homePageLoading && b2bPackagesLoading&& halalPackagesLoading) {
    return <LoadingScreen />;
  }

  // Only render the rest of the UI after loading is complete
  return (
    <Grid container spacing={0} alignItems="left" className={classes.headerContainer}>
      {/* Logo */}
      <Grid item xs={1} style={{ flex: '1 1 auto' }}>
        <img
          src={logoUrl || '/default-logo.png'}
          alt="Logo"
          loading="lazy"
          className={classes.logo}
          onClick={() => navigate('/home')}
        />
      </Grid>

      {/* Conditional Navigation Button */}
      <Grid item xs={10}>
        <NavigationButton
          homePageLoading={homePageLoading}
          b2bPackages={b2bPackages}
          homePage={homePage?.length > 0 ? homePage[0] : null}
          className={classes.navButton}
          allLandPackages={allLandPackages}
          allUmrahPackages={allUmrahPackages}
          allHalalPackages={allHalalPackages}
        >
          Navigate
        </NavigationButton>
      </Grid>

      {/* Visa Button */}
      <Grid
  item
  xs={1}
  sx={{
    [theme.breakpoints.down('md')]: {
      position: 'absolute', // Make the button fixed for top-right placement
      top: '120px', // Distance from the top
      right: '50px', // Distance from the right
      zIndex: 1000, // Ensure it's above other elements
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute', // Keep the button positioned absolutely
      top: '10px', // Distance from the top
      left: '50%', // Center it horizontally
      transform: 'translateX(-50%)', // Adjust the position to truly center it
      zIndex: 1000, // Ensure it's above other elements
      textAlign: 'center',
    },
    
    
  }}
>
  <Button
    onClick={() => window.open('https://www.google.com', '_blank')}
    sx={{
      fontWeight: 'bold',
      borderRadius: '50px',
      padding: '12px 20px',
      backgroundColor: '#FFD700', // Bright Gold for visibility
      color: '#004e8c', // Dark Blue for contrast
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: '#FFA500', // Orange on hover
        color: '#FFFFFF', // White text on hover
      },
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Enhanced shadow for emphasis
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px', // Adjust size for smaller screens
      },
    }}
  >
    Visa
  </Button>
</Grid>



    </Grid>
  );
};

export default Header;
