import logo from './logo.svg';
import 'src/App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navigation from "src/components/navigation";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import Home from "src/components/home";
import {store} from "src/store/store";
import {Provider} from 'react-redux'
import 'src/assets/css/index.css'
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material';
import { CssBaseline, Box } from "@mui/material";
import HotelList from './components/LandPackages/LandPackages';
import ContactUsForm from './components/ContactUs/contactUs';
import UmrahPackageDetails from './components/UmrahPackageCardDetails';
import BookingForm from './components/BookingDetails';
import BusinessCollaborationForm from './components/BuisnessCollaborationForm'
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndCondition';
import OwnerDetails from './components/OwnersDetail';
import SuccessfulPayment from './components/PaymentStatus/SuccessfulPayment';
import NotSuccessfulPayment from './components/PaymentStatus/NotSuccessfulPayment';
import PackagesDisplay from './components/PackagesDisplay';

const theme = createTheme({
    palette: {
        primary: {
            main: '#004E8C', // Dark blue for the header and footer
        },
        secondary: {
            main: '#FAF3E0', // Light beige for the background sections
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
      fontFamily: "'Tajawal', 'Arial', sans-serif", // Use your chosen font
      h1: {
        fontFamily: "'Amiri', 'Tajawal', serif", // Custom font for h1
        fontWeight: 700,
        fontSize: '2.5rem',
      },
      h2: {
        fontFamily: "'Amiri', 'Tajawal', serif", // Custom font for h2
        fontWeight: 600,
        fontSize: '2rem',
      },
      h3: {
        fontFamily: "'Amiri', 'Tajawal', serif", // Custom font for h3
        fontWeight: 500, // Medium weight
        fontSize: '1.75rem', // Slightly larger for h3
      },
      h4: {
        fontFamily: "'Amiri', 'Tajawal', serif", // Custom font for h4
        fontWeight: 500, // Medium weight
        fontSize: '1.5rem', // Slightly smaller for h4
      },
      body1: {
        fontFamily: "'Tajawal', sans-serif", // Default body text
        fontWeight: 400,
        fontSize: '1rem',
      },
      button: {
        fontFamily: "'Tajawal', sans-serif", // Button font
        textTransform: 'none', // Keep button text normal (not uppercase)
      },
    },
});




function App() {
    return (
        <>
           <ThemeProvider theme={theme}>
            
      <Provider store={store}>
        <Router>
          {/* MUI's Box for styling */}
          <Box sx={{ backgroundColor: 'white', minHeight: '100vh', paddingBottom: '20px' }}>
            <CssBaseline /> {/* Ensures consistent padding/margin resets */}
            <Routes>
              <Route path="/" element={<Navigation />}>
                <Route path="home" element={<Home />} />
                
                <Route path="hotels" element={<HotelList />} />
                <Route path="get-in-touch" element={<ContactUsForm />} />
                <Route path="package/:packageId/details" element={<UmrahPackageDetails />} />
                <Route path="booking-details" element={<BookingForm />} />
                <Route path="business-collaboration" element={<BusinessCollaborationForm />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="owners-details" element={<OwnerDetails />} />
                <Route path="payment/success/:id" element={<SuccessfulPayment />} />
                <Route path="payment/failure/:id" element={<NotSuccessfulPayment />} />
                <Route path="packages-display" element = {<PackagesDisplay/>}/>
              </Route>
            </Routes>
          </Box>
        </Router>
      </Provider>
    </ThemeProvider>
        </>
    );
}

export default App;